import AdminApi from '../api/AdminApi';
import EnvironmentVariables from '../EnvironmentVariables';
import { IOrganisation } from '../interfaces/IOrganisation';
import { IImageUploadRequest, ImageRequestGroup } from '../views/Editor/ImageUploader';
import GuidHelper from './GuidHelper';
import ThemeHelper from './ThemeHelper';
import UserHelper from './UserHelper';

export default class OrganisationHelper {
  public static async hashEmail(email: string, orgTag: string): Promise<string> {
    const combined = `${orgTag.trim().toLowerCase()}::${email.trim().toLowerCase()}`;
    const data = new TextEncoder().encode(combined);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    return Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  }

  public static async isMember(email: string, organisation: IOrganisation) {
    if (!organisation.Links) return false;
    const hashed = await OrganisationHelper.hashEmail(email.toLowerCase(), organisation.OrganisationTag.toLowerCase());
    return organisation.Links.includes(hashed);
  }

  public static save(
    organisation: IOrganisation,
    setBusyMessage: (message: string) => void,
    imageRequests: { [key: string]: IImageUploadRequest } = null,
  ): Promise<IOrganisation> {
    setBusyMessage && setBusyMessage('Saving organisation...');

    return AdminApi.request('PUT', '/api/OrganisationEdit', organisation)
      .then((o: IOrganisation) => {
        if (imageRequests) {
          return OrganisationHelper.saveImages(o, setBusyMessage, imageRequests);
        } else {
          return o;
        }
      })
      .then((o) => {
        setBusyMessage && setBusyMessage('Updating organisation cache...');
        UserHelper.refreshUserToken();
        return AdminApi.request('PUT', '/api/OrganisationEdited', o);
      })
      .then((o) => {
        setBusyMessage && setBusyMessage('All done!');
        return o;
      });
  }

  public static saveImages(
    organisation: IOrganisation,
    setBusyMessage: (message: string) => void,
    imageRequests: { [key: string]: IImageUploadRequest } = null,
  ): Promise<IOrganisation> {
    setBusyMessage('Saving images...');

    return Promise.all(
      Object.keys(imageRequests).map((key) => {
        const imageRequest = imageRequests[key];

        const formData = new FormData();
        formData.append('organisationId', organisation.Id.toString());
        formData.append('group', imageRequest.group);
        formData.append('file', imageRequest.file);

        return fetch(
          `${EnvironmentVariables.ADMIN_API_URL}/api/OrganisationImageUpload?organisationId=${organisation.Id}&group=${imageRequest.group}`,
          {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              Authorization: `Bearer ${UserHelper.currentUser.Token}`,
            },
            body: formData,
          },
        )
          .then((response) => {
            return response.json();
          })
          .then((response: any) => {
            if (imageRequest.group == ImageRequestGroup.Organisation) {
              organisation.LogoUrl = response.url;
            }
          });
      }),
    ).then(() => {
      return organisation;
    });
  }

  public static getDefault(): IOrganisation {
    var organisation: IOrganisation = {
      Tours: [],
      StreetAddress: '',
      City: '',
      Postcode: '',
      DisableTheme: true,
      Id: 0,
      Roles: [],
      Name: '',
      WebsiteUrl: '',
      Description: '',
      OrganisationTag: '',
      BoxOfficeTel: '',
      BoxOfficeEmail: '',
      Users: [],
      Awards: [],
      CreatedBy: UserHelper.currentUser,
      MembershipsEnabled: false,
      MemberOnlyRequests: false,
      AutoAcceptMemberRequests: false,
      CardFeePercentage: null,
      BoxOfficeNo: '',
      CustomFee: null,
      Guid: GuidHelper.new(),
      ServiceFee: null,
      StripeAccountId: null,
      StripeConnectEnabled: null,
      Theme: ThemeHelper.getDefaultTheme(),
      MemberTypes: [
        {
          Name: 'Member',
          Quota: 0,
          Default: true,
        },
      ],
      LogoUrl: null,
    };

    organisation.Users.push(UserHelper.currentUser);

    return organisation;
  }
}
