import React, { FunctionComponent } from 'react';

export interface IProps {}

export default ((props) => {
  return (
    <svg
      className="features-background"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 5716.14"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="148.69"
          y1="5393"
          x2="1734.91"
          y2="4282.32"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6acae8" />
          <stop offset=".98" stopColor="#def2f7" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="52.9"
          y1="3010.04"
          x2="1888.2"
          y2="1724.95"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="428.98"
          y1="1552.72"
          x2="2085.38"
          y2="681.44"
          gradientTransform="translate(-246.99 83.56) rotate(-4.42)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#44bbe4" />
          <stop offset=".98" stopColor="#c5e8f3" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="281.71"
          y1="1215.59"
          x2="1872.93"
          y2="378.59"
          gradientTransform="matrix(1,0,0,1,0,0)"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="336.51"
          y1="1127.35"
          x2="2048"
          y2="423.21"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".07" stopColor="#7acde8" />
          <stop offset="1" stopColor="#bae5f0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="175.87"
          y1="5222.6"
          x2="1737.8"
          y2="4401"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="135.71"
          y1="5744.17"
          x2="1697.64"
          y2="4922.57"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="304.99"
          y1="5499.43"
          x2="1830.04"
          y2="4697.23"
          gradientTransform="matrix(1,0,0,1,0,0)"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="224.53"
          y1="5184.41"
          x2="1588.67"
          y2="4225.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#76cdea" />
          <stop offset=".98" stopColor="#c5e8f3" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-10"
          x1="350.21"
          y1="5414.53"
          x2="2015.49"
          y2="4729.4"
          xlinkHref="#linear-gradient-5"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="293.3"
          y1="2902.56"
          x2="2057.5"
          y2="1974.56"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="334"
          y1="2368.59"
          x2="2098.2"
          y2="1440.6"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="255.2"
          y1="3339.73"
          x2="1921.94"
          y2="2463.01"
          gradientTransform="matrix(1,0,0,1,0,0)"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="108"
          y1="2416.36"
          x2="1694.93"
          y2="1300.98"
          xlinkHref="#linear-gradient-9"
        />
        <linearGradient
          id="linear-gradient-15"
          x1="323.48"
          y1="3288.66"
          x2="2087.72"
          y2="2562.82"
          xlinkHref="#linear-gradient-5"
        />
        <linearGradient
          id="linear-gradient-16"
          x1="149.12"
          y1="4256.25"
          x2="1985.63"
          y2="3290.22"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-17"
          x1="191.63"
          y1="3677.7"
          x2="2028.14"
          y2="2711.67"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-18"
          x1="234.21"
          y1="4579.59"
          x2="1951.61"
          y2="3676.22"
          gradientTransform="matrix(1,0,0,1,0,0)"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-19"
          x1="44.59"
          y1="3717.31"
          x2="1711.17"
          y2="2545.95"
          xlinkHref="#linear-gradient-9"
        />
        <linearGradient
          id="linear-gradient-20"
          x1="-17.15"
          y1="4355.3"
          x2="1902.98"
          y2="3010.81"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-21"
          x1="316.91"
          y1="4506.75"
          x2="2116.53"
          y2="3766.35"
          xlinkHref="#linear-gradient-5"
        />
      </defs>
      <g id="Swish">
        <path
          d="M0,5051.48s1111.13-273.6,1920-835.67v382.54S1032.65,5031.22,0,5237.59v-186.1Z"
          style={{ fill: 'url(#linear-gradient)' }}
        />
        <path
          d="M0,2763.78s1111.13-450.57,1920-1376.2v629.98S1032.65,2660.52,0,3000.37v-236.59Z"
          style={{ fill: 'url(#linear-gradient-2)' }}
        />
        <path
          d="M1920,594.3S1290.02,1087.36,0,1512.35v77.24s1070.17-246.03,1920-734.3v-260.99Z"
          style={{ fill: 'url(#linear-gradient-3)' }}
        />
        <path
          d="M1920,397.48S1333.39,763.85,0,1118.7v75.96s1068.87-233.31,1920-595.76v-201.42Z"
          style={{ fill: 'url(#linear-gradient-4)' }}
        />
        <path
          d="M0,1040.57s1309-179.87,1920-518.2v177.49S1358.43,972.2,0,1137.15v-96.58Z"
          style={{ fill: 'url(#linear-gradient-5)' }}
        />
        <path
          d="M1920,4428.82S1290.02,4807.5,0,5133.89v59.32s934-129.85,1920-564.49v-199.91Z"
          style={{ fill: 'url(#linear-gradient-6)' }}
        />
        <path
          d="M1920,4951.74S1290.02,5330.42,0,5656.82v59.32s1070.17-188.96,1920-563.95v-200.44Z"
          style={{ fill: 'url(#linear-gradient-7)' }}
        />
        <path
          d="M1920,4800.59S1333.39,5081.96,0,5354.49v58.34s1068.87-179.19,1920-457.55v-154.7Z"
          style={{ fill: 'url(#linear-gradient-8)' }}
        />
        <path
          d="M1920,4144.62S1009.13,4691.91,0,5023.66v43.71s928-231.83,1920-756.06v-166.69Z"
          style={{ fill: 'url(#linear-gradient-9)' }}
        />
        <path
          d="M0,5294.49s1309-138.14,1920-397.98v136.31S1358.43,5241.98,0,5368.66v-74.18Z"
          style={{ fill: 'url(#linear-gradient-10)' }}
        />
        <path
          d="M1920,1767.37S1290.02,2390.98,0,2928.5v97.69s934-213.84,1920-929.6v-329.21Z"
          style={{ fill: 'url(#linear-gradient-11)' }}
        />
        <path
          d="M1920,1232.72S1290.02,1856.33,0,2393.84v97.69s1070.17-311.18,1920-928.73v-330.09Z"
          style={{ fill: 'url(#linear-gradient-12)' }}
        />
        <path
          d="M1920,2385.37S1333.39,2848.75,0,3297.56v96.08s1068.87-295.09,1920-753.5v-254.76Z"
          style={{ fill: 'url(#linear-gradient-13)' }}
        />
        <path
          d="M1920,978.58S1009.13,1879.85,0,2426.19v71.98s928-381.78,1920-1245.09v-274.5Z"
          style={{ fill: 'url(#linear-gradient-14)' }}
        />
        <path
          d="M0,3233.49s1309-227.49,1920-655.41v224.48S1358.43,3147.02,0,3355.64v-122.15Z"
          style={{ fill: 'url(#linear-gradient-15)' }}
        />
        <path
          d="M1920,3034.67S1290.02,3745.84,0,4358.83v111.41s934-243.87,1920-1060.13v-375.43Z"
          style={{ fill: 'url(#linear-gradient-16)' }}
        />
        <path
          d="M1920,2458.02S1290.02,3169.19,0,3782.18v111.41s1070.17-354.87,1920-1059.13v-376.43Z"
          style={{ fill: 'url(#linear-gradient-17)' }}
        />
        <path
          d="M1920,3539.93S1333.39,4068.37,0,4580.2v109.57s1068.87-336.52,1920-859.3v-290.53Z"
          style={{ fill: 'url(#linear-gradient-18)' }}
        />
        <path
          d="M1920,2168.2S1009.13,3196.02,0,3819.07v82.09s928-435.39,1920-1419.91v-313.04Z"
          style={{ fill: 'url(#linear-gradient-19)' }}
        />
        <path
          d="M0,4204.07s1111.13-513.83,1920-1569.43v718.43S1032.65,4061.15,0,4448.72v-244.65Z"
          style={{ fill: 'url(#linear-gradient-20)' }}
        />
        <path
          d="M0,4467.5s1309-259.43,1920-747.43v256S1358.43,4368.89,0,4606.8v-139.3Z"
          style={{ fill: 'url(#linear-gradient-21)' }}
        />
      </g>
      <g id="Stars_Dots" data-name="Stars &amp; Dots">
        <path
          d="M153.39,3520.07s.26,20,14.52,20c0,0-14.17,5.04-14.17,17.3,0,0-4.7-17.57-12.52-17.57,0,0,12.17-2.09,12.17-19.74Z"
          style={{ fill: '#d7eff8' }}
        />
        <path
          d="M1839.47,4109.77s-1.41-27.63-21.12-26.88c0,0,19.33-7.72,18.68-24.66,0,0,7.41,24.03,18.23,23.62,0,0-16.72,3.52-15.79,27.92Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M880.07,4231.24s-1.41-27.63-21.12-26.88c0,0,19.33-7.72,18.68-24.66,0,0,7.41,24.03,18.23,23.62,0,0-16.72,3.52-15.79,27.92Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M835.32,3390.58s-1.41-27.63-21.12-26.88c0,0,19.33-7.72,18.68-24.66,0,0,7.41,24.03,18.23,23.62,0,0-16.72,3.52-15.79,27.92Z"
          style={{ fill: '#f7fcfe' }}
        />
        <path
          d="M777.55,3564.93s-.59-11.46-8.76-11.15c0,0,8.01-3.2,7.75-10.23,0,0,3.07,9.97,7.56,9.79,0,0-6.93,1.46-6.55,11.58Z"
          style={{ fill: '#8cd1e6' }}
        />
        <path
          d="M757.78,3656.43s2.45,18.11,15.39,16.54c0,0-12.3,6.14-10.94,17.27,0,0-6.2-15.41-13.3-14.55,0,0,10.81-3.24,8.86-19.25Z"
          style={{ fill: '#2cb2da' }}
        />
        <circle cx="174.03" cy="4296.58" r="15.25" style={{ fill: '#a2dbed' }} />
        <circle cx="1744.2" cy="3429.8" r="20.92" style={{ fill: '#a2dbed' }} />
        <circle cx="1171.91" cy="4007.88" r="13.02" style={{ fill: '#a2dbed' }} />
        <circle cx="1611.36" cy="4311.83" r="8.21" style={{ fill: '#a2dbed' }} />
        <circle cx="464.43" cy="3408.88" r="15.25" style={{ fill: '#a2dbed' }} />
        <path
          d="M1856.55,2359.38s-.28,20-15.41,20c0,0,15.04,5.04,15.04,17.3,0,0,4.98-17.57,13.29-17.57,0,0-12.92-2.09-12.92-19.74Z"
          style={{ fill: '#d7eff8' }}
        />
        <path
          d="M67.28,2949.08s1.5-27.63,22.41-26.88c0,0-20.51-7.72-19.82-24.66,0,0-7.87,24.03-19.35,23.62,0,0,17.74,3.52,16.76,27.92Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M1085.41,3070.56s1.5-27.63,22.41-26.88c0,0-20.51-7.72-19.82-24.66,0,0-7.87,24.03-19.35,23.62,0,0,17.74,3.52,16.76,27.92Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M1132.89,2229.89s1.5-27.63,22.41-26.88c0,0-20.51-7.72-19.82-24.66,0,0-7.87,24.03-19.35,23.62,0,0,17.74,3.52,16.76,27.92Z"
          style={{ fill: '#f7fcfe' }}
        />
        <path
          d="M1194.2,2404.25s.62-11.46,9.3-11.15c0,0-8.5-3.2-8.22-10.23,0,0-3.26,9.97-8.02,9.79,0,0,7.36,1.46,6.95,11.58Z"
          style={{ fill: '#8cd1e6' }}
        />
        <path
          d="M1215.18,2495.74s-2.6,18.11-16.33,16.54c0,0,13.05,6.14,11.61,17.27,0,0,6.58-15.41,14.12-14.55,0,0-11.47-3.24-9.4-19.25Z"
          style={{ fill: '#2cb2da' }}
        />
        <ellipse cx="1834.65" cy="3135.89" rx="16.18" ry="15.25" style={{ fill: '#a2dbed' }} />
        <ellipse cx="168.39" cy="2269.11" rx="22.2" ry="20.92" style={{ fill: '#a2dbed' }} />
        <ellipse cx="775.7" cy="2847.19" rx="13.81" ry="13.02" style={{ fill: '#a2dbed' }} />
        <ellipse cx="309.36" cy="3151.14" rx="8.71" ry="8.21" style={{ fill: '#a2dbed' }} />
        <ellipse cx="1526.48" cy="2248.19" rx="16.18" ry="15.25" style={{ fill: '#a2dbed' }} />
        <path
          d="M132.43,1203.29s.26,20,14.52,20c0,0-14.17,5.04-14.17,17.3,0,0-4.7-17.57-12.52-17.57,0,0,12.17-2.09,12.17-19.74Z"
          style={{ fill: '#d7eff8' }}
        />
        <path
          d="M1818.51,1793s-1.41-27.63-21.12-26.88c0,0,19.33-7.72,18.68-24.66,0,0,7.41,24.03,18.23,23.62,0,0-16.72,3.52-15.79,27.92Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M859.11,1914.47s-1.41-27.63-21.12-26.88c0,0,19.33-7.72,18.68-24.66,0,0,7.41,24.03,18.23,23.62,0,0-16.72,3.52-15.79,27.92Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M814.36,1073.8s-1.41-27.63-21.12-26.88c0,0,19.33-7.72,18.68-24.66,0,0,7.41,24.03,18.23,23.62,0,0-16.72,3.52-15.79,27.92Z"
          style={{ fill: '#f7fcfe' }}
        />
        <path
          d="M756.59,1248.16s-.59-11.46-8.76-11.15c0,0,8.01-3.2,7.75-10.23,0,0,3.07,9.97,7.56,9.79,0,0-6.93,1.46-6.55,11.58Z"
          style={{ fill: '#8cd1e6' }}
        />
        <path
          d="M736.82,1339.65s2.45,18.11,15.39,16.54c0,0-12.3,6.14-10.94,17.27,0,0-6.2-15.41-13.3-14.55,0,0,10.81-3.24,8.86-19.25Z"
          style={{ fill: '#2cb2da' }}
        />
        <circle cx="153.07" cy="1979.8" r="15.25" style={{ fill: '#a2dbed' }} />
        <circle cx="1723.24" cy="1113.03" r="20.92" style={{ fill: '#a2dbed' }} />
        <circle cx="1150.95" cy="1691.1" r="13.02" style={{ fill: '#a2dbed' }} />
        <circle cx="1590.4" cy="1995.05" r="8.21" style={{ fill: '#a2dbed' }} />
        <circle cx="443.47" cy="1092.1" r="15.25" style={{ fill: '#a2dbed' }} />
        <path
          d="M1874.44,181.03s-.25,20-13.85,20c0,0,13.52,5.04,13.52,17.3,0,0,4.48-17.57,11.94-17.57,0,0-11.61-2.09-11.61-19.74Z"
          style={{ fill: '#d7eff8' }}
        />
        <path
          d="M266.31,770.74s1.35-27.63,20.15-26.88c0,0-18.43-7.72-17.82-24.66,0,0-7.07,24.03-17.39,23.62,0,0,15.94,3.52,15.06,27.92Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M1181.36,892.21s1.35-27.63,20.15-26.88c0,0-18.43-7.72-17.82-24.66,0,0-7.07,24.03-17.39,23.62,0,0,15.94,3.52,15.06,27.92Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M1224.04,51.54s1.35-27.63,20.15-26.88c0,0-18.43-7.72-17.82-24.66,0,0-7.07,24.03-17.39,23.62,0,0,15.94,3.52,15.06,27.92Z"
          style={{ fill: '#f7fcfe' }}
        />
        <path
          d="M1279.14,225.9s.56-11.46,8.35-11.15c0,0-7.64-3.2-7.39-10.23,0,0-2.93,9.97-7.21,9.79,0,0,6.61,1.46,6.24,11.58Z"
          style={{ fill: '#8cd1e6' }}
        />
        <path
          d="M1298,317.4s-2.34,18.11-14.68,16.54c0,0,11.73,6.14,10.44,17.27,0,0,5.92-15.41,12.69-14.55,0,0-10.31-3.24-8.45-19.25Z"
          style={{ fill: '#2cb2da' }}
        />
        <ellipse cx="1854.76" cy="957.55" rx="14.54" ry="15.25" style={{ fill: '#a2dbed' }} />
        <ellipse cx="357.18" cy="90.77" rx="19.96" ry="20.92" style={{ fill: '#a2dbed' }} />
        <ellipse cx="903.01" cy="668.84" rx="12.41" ry="13.02" style={{ fill: '#a2dbed' }} />
        <ellipse cx="483.88" cy="972.79" rx="7.83" ry="8.21" style={{ fill: '#a2dbed' }} />
        <ellipse cx="1577.78" cy="69.85" rx="14.54" ry="15.25" style={{ fill: '#a2dbed' }} />
        <path
          d="M1806.1,4712.99s-.27,23.03-14.76,23.03c0,0,14.4,5.81,14.4,19.92,0,0,4.77-20.23,12.72-20.23,0,0-12.37-2.4-12.37-22.73Z"
          style={{ fill: '#d7eff8' }}
        />
        <path
          d="M92.93,5392s1.43-31.81,21.46-30.95c0,0-19.64-8.88-18.98-28.4,0,0-7.53,27.67-18.52,27.2,0,0,16.98,4.06,16.04,32.15Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M1067.75,5531.87s1.43-31.81,21.46-30.95c0,0-19.64-8.88-18.98-28.4,0,0-7.53,27.67-18.52,27.2,0,0,16.98,4.06,16.04,32.15Z"
          style={{ fill: '#2cb2da' }}
        />
        <path
          d="M1113.22,4563.89s1.43-31.81,21.46-30.95c0,0-19.64-8.88-18.98-28.4,0,0-7.53,27.67-18.52,27.2,0,0,16.98,4.06,16.04,32.15Z"
          style={{ fill: '#f7fcfe' }}
        />
        <path
          d="M1171.91,4764.66s.59-13.19,8.9-12.83c0,0-8.14-3.68-7.87-11.78,0,0-3.12,11.47-7.68,11.28,0,0,7.04,1.68,6.65,13.33Z"
          style={{ fill: '#8cd1e6' }}
        />
        <path
          d="M1192.01,4870.01s-2.49,20.86-15.64,19.04c0,0,12.5,7.08,11.12,19.88,0,0,6.3-17.75,13.52-16.75,0,0-10.99-3.73-9-22.17Z"
          style={{ fill: '#2cb2da' }}
        />
        <ellipse cx="771.22" cy="5274.68" rx="13.22" ry="14.99" style={{ fill: '#a2dbed' }} />
        <ellipse cx="324.71" cy="5624.66" rx="8.34" ry="9.45" style={{ fill: '#a2dbed' }} />
        <ellipse cx="1490.06" cy="4584.97" rx="15.49" ry="17.56" style={{ fill: '#a2dbed' }} />
      </g>
    </svg>
  );
}) as FunctionComponent<IProps>;
