import linq from 'linq';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import Button from '../../components/Button';
import { IEvent } from '../../interfaces/IEvent';
import { EventDateTimeType, IEventDate } from '../../interfaces/IEventDate';
import Constants from '../../helpers/Constants';
import { Link } from 'react-router-dom';
import EventHelper from '../../helpers/EventHelper';
import EnvironmentVariables from '../../EnvironmentVariables';
import FeeHelper from '../../helpers/FeeHelper';

interface IProps {
  isAdmin: boolean;
  events: IEvent[];
  showPastDates: boolean;
  onVenueLinkClick?: () => void;
  onEventDateClicked?: (e, date) => void;
}

const externalEventDateClick = (_event, date) => {
  var r = window.confirm(
    'You are about to leave Seaty.co.uk, we take no responsibility for ticket sales that occur outside of our domain. Click continue to go to the external ticket sales provider.',
  );
  if (r == true) {
    window.location.href = date.ExternalUrl;
    return;
  } else {
    return;
  }
};

const getDatesData = (isAdmin, event, dayRows) => {
  const hasAvailableDates = linq
    .from(dayRows)
    .any((dr) =>
      linq
        .from(dr.toArray())
        .any((t) => !t.SoldOut && t.PercentageOfNotLockedSold <= Constants.YellowPercentageEventDate),
    );
  const hasBusyDates = linq
    .from(dayRows)
    .any((dr) =>
      linq
        .from(dr.toArray())
        .any(
          (t) =>
            !t.SoldOut &&
            t.PercentageOfNotLockedSold > Constants.YellowPercentageEventDate &&
            t.PercentageOfNotLockedSold <= Constants.RedPercentageEventDate,
        ),
    );
  const hasLimitedDates = linq
    .from(dayRows)
    .any((dr) =>
      linq
        .from(dr.toArray())
        .any(
          (t) =>
            !t.SoldOut &&
            t.PercentageOfNotLockedSold > Constants.RedPercentageEventDate &&
            t.PercentageOfNotLockedSold < 100,
        ),
    );

  return {
    hasAvailableDates: hasAvailableDates,
    hasBusyDates: hasBusyDates,
    hasLimitedDates: hasLimitedDates,
    dayRows: dayRows.map((dayRow) => {
      return {
        dayRow: dayRow,
        times: dayRow.toArray().map((eventDate: IEventDate) => {
          const isPast = moment(eventDate.DateAsString).add(12, 'hours') <= moment();

          return {
            isPast: isPast,
            eventDate: eventDate,
            timeString: eventDate.TimeString,
            lowestPrice: `${FeeHelper.getAmountWithFeesFormatted(isAdmin, event, eventDate.LowestPrice)}`,
            highestPrice: `${FeeHelper.getAmountWithFeesFormatted(isAdmin, event, eventDate.HighestPrice)}`,
            percentageOfNotLockedSold: eventDate.PercentageOfNotLockedSold,
            soldOut: eventDate.SoldOut,
            availableCategories: eventDate.AvailableCategories,
            availabilityClassName:
              eventDate.AvailableCategories.length == 0 || eventDate.SoldOut || isPast || eventDate.Suspend
                ? ' sold-out'
                : eventDate.PercentageOfNotLockedSold > Constants.RedPercentageEventDate
                  ? ' almost-sold-out'
                  : eventDate.PercentageOfNotLockedSold > Constants.YellowPercentageEventDate
                    ? ' selling-well'
                    : '',
          };
        }),
      };
    }),
  };
};

const EventDates: FunctionComponent<IProps> = (props) => {
  const { onEventDateClicked, events, isAdmin, showPastDates } = props;

  const eventsData = events
    .map((event) => {
      let pastEventDates = EventHelper.getPastDates(event, isAdmin);
      const futureEventDates = EventHelper.getFutureDates(event, isAdmin);
      const dates = showPastDates ? pastEventDates.concat(futureEventDates) : futureEventDates;

      console.log(dates);

      return {
        event: event,
        hasDates: dates.length > 0,
        datesData: getDatesData(isAdmin, event, dates),
      };
    })
    .filter((e) => e.hasDates);

  let showFeesMessage = linq
    .from(events)
    .any(
      (e: IEvent) =>
        (e.HandlingFeePercentage && e.HandlingFeePercentage > 0) ||
        (e.HandlingFee && e.HandlingFee > 0) ||
        !e.AbsorbFee,
    );

  let anyTimesShown = false;
  const isTesting = EnvironmentVariables.isDev();

  const eventRows = eventsData.map((eventData) => {
    const event = eventData.event;
    const datesData = eventData.datesData;

    const now = event && moment().tz(event.TimeZoneIana);
    const onlineFrom = event && moment(event.OnlineFromDate).tz(event.TimeZoneIana);
    const salesStarted = event && onlineFrom <= now;

    const onlineFromTime = onlineFrom.format('h:mma') == '12:00am' ? 'midnight' : onlineFrom.format('h:mma');

    if (salesStarted || isAdmin) anyTimesShown = true;

    const getEventDateLabel = (eventDate: IEventDate) => {
      var startDate = moment(eventDate.DateAsString);
      var endDate = moment(eventDate.EndDateAsString);

      if (eventDate.TimeType === EventDateTimeType.SingleTime) {
        return startDate.format('h:mma');
      } else if (eventDate.TimeType === EventDateTimeType.AllDay) {
        return 'All Day';
      } else if (eventDate.TimeType === EventDateTimeType.DateRange) {
        return `${startDate.format('ddd Do')}${startDate.month() == endDate.month() && startDate.year() == endDate.year() ? '' : startDate.format(' MMM')}${
          startDate.year() == endDate.year() ? '' : startDate.format(' YYYY')
        } - ${endDate.format('ddd Do MMM YYYY')}`;
      } else if (eventDate.TimeType === EventDateTimeType.TimeRange) {
        return `${startDate.format('h:mma')} - ${endDate.format('h:mma')}`;
      } else {
        return startDate.format('h:mma');
      }
    };

    return (
      <React.Fragment key={`Event_${event.Id}`}>
        {props.onVenueLinkClick && (
          <Link
            className="event-dates-list-venue"
            to={`/${event.EventTag}`}
            onClick={props.onVenueLinkClick && props.onVenueLinkClick}
          >
            {event.VenueName}
            {event.Private && <div className="tour">Private Event</div>}
          </Link>
        )}

        {!salesStarted && (
          <p className="event-dates-list-info">
            Sales start on <strong>{onlineFrom.format('ddd Do MMM YYYY')}</strong> at <strong>{onlineFromTime}</strong>.
          </p>
        )}

        {(salesStarted || isAdmin) && (
          <>
            {((datesData.hasAvailableDates && (datesData.hasBusyDates || datesData.hasLimitedDates)) ||
              datesData.hasBusyDates ||
              datesData.hasLimitedDates) && (
              <div className="event-dates-list-key">
                {datesData.hasAvailableDates && (datesData.hasBusyDates || datesData.hasLimitedDates) && (
                  <div className="event-dates-list-key_available">Available</div>
                )}
                {datesData.hasBusyDates && <div className="event-dates-list-key_selling-well">Busy</div>}
                {datesData.hasLimitedDates && <div className="event-dates-list-key_almost-sold-out">Limited</div>}
              </div>
            )}

            {datesData.dayRows.map((data) => {
              var startDate = moment(data.times[0].eventDate.DateAsString);
              var endDate = moment(data.times[0].eventDate.EndDateAsString);

              const notOnlyDateRange = linq
                .from(data.times)
                .any((t) => t.eventDate.TimeType !== EventDateTimeType.DateRange);

              return (
                <React.Fragment key={`${data.dayRow.key()} `}>
                  <div>
                    <div className="event-dates-list-date">
                      {notOnlyDateRange
                        ? moment(data.times[0].eventDate.DateAsString).format('dddd Do MMMM YYYY')
                        : `${startDate.format('ddd Do')}${startDate.month() == endDate.month() && startDate.year() == endDate.year() ? '' : startDate.format(' MMM')}${
                            startDate.year() == endDate.year() ? '' : startDate.format(' YYYY')
                          } - ${endDate.format('ddd Do MMM YYYY')}`}
                    </div>
                    <div className="button-list">
                      {data.times.map((timeData) => {
                        const {
                          isPast,
                          eventDate,
                          availableCategories,
                          timeString,
                          soldOut,
                          lowestPrice,
                          highestPrice,
                          percentageOfNotLockedSold,
                        } = timeData;

                        const key = `${data.dayRow.key()}_${eventDate.DateAsString}`;
                        const className = `confirm has-prices${eventDate.External ? ' external' : ''}${timeData.availabilityClassName}`;

                        const label = eventDate.Suspend ? (
                          <div className="date-prices">Suspended</div>
                        ) : isPast ? (
                          <div className="date-prices">Past event</div>
                        ) : soldOut ? (
                          <div className="date-prices">Sold out</div>
                        ) : availableCategories.length != 0 ? (
                          <div className="date-prices">
                            {lowestPrice == highestPrice ? lowestPrice : `${lowestPrice} - ${highestPrice}`}
                          </div>
                        ) : availableCategories.length == 0 ? (
                          <div className="date-prices">Unavailable</div>
                        ) : (
                          <></>
                        );

                        if (eventDate.External && eventDate.ExternalUrl && eventDate.ExternalUrl.length > 0) {
                          return (
                            <Button
                              key={key}
                              className={className}
                              onClick={() => externalEventDateClick(event, eventDate)}
                              text={!notOnlyDateRange ? 'Book tickets' : getEventDateLabel(eventDate)}
                            >
                              {isTesting && (
                                <div id="eventDateId" style={{ opacity: 0.01, position: 'absolute' }}>
                                  {eventDate.Id}
                                </div>
                              )}
                              {label}
                            </Button>
                          );
                        }

                        return (
                          <Link
                            onClick={onEventDateClicked ? () => onEventDateClicked(event, eventDate) : null}
                            to={`/${event.EventTag}/tickets/${moment(eventDate.DateAsString).format('DDMMMYYYY/HHmm')}`}
                            key={key}
                            className={className}
                          >
                            {isTesting && (
                              <div id="eventDateId" style={{ opacity: 0.01, position: 'absolute' }}>
                                {eventDate.Id}
                              </div>
                            )}
                            {!notOnlyDateRange ? 'Book tickets' : getEventDateLabel(eventDate)}
                            {label}
                          </Link>
                        );
                      })}
                    </div>
                  </div>

                  <div className="spacer" />
                </React.Fragment>
              );
            })}

            <div className="spacer" />
          </>
        )}
      </React.Fragment>
    );
  });

  const event = eventsData && eventsData.length > 0 && eventsData[0].event;

  return (
    <>
      <div className="event-dates-list">
        {eventRows}
        {anyTimesShown && (
          <>
            {linq.from(eventsData).any((ed) => ed.event.HasExternalEvents) && (
              <>
                <p>
                  * Times marked with an asterisk are handled outside of the jurisdiction of Seaty.co.uk and provided as
                  a link to an external service.
                </p>
              </>
            )}
            {showFeesMessage && <p>{FeeHelper.getServiceFeeMessage(props.isAdmin, eventsData[0].event)}</p>}
            <div className="spacer" />
          </>
        )}
      </div>
    </>
  );
};

export default EventDates;
