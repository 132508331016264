import ReactGA from 'react-ga4';
import CurrencyHelper from './CurrencyHelper';
import { IEvent } from '../interfaces/IEvent';

export default class FeeHelper {
  public static getAmountWithFees(event: IEvent, amountInt: number) {
    if (event.AbsorbFee) return amountInt;

    const calculatedFee = Math.max(Math.round(amountInt * (event.CardFeePercentage / 100.0)), event.CardFeeMinimum);

    return amountInt + calculatedFee;
  }

  public static getAmountWithFeesFormatted(isAdmin: boolean, event: IEvent, amountInt: number) {
    var returnWithoutFees =
      (event.Organisation &&
        event.Organisation.MembershipsEnabled &&
        event.Organisation.IsMember &&
        event.RequestingEnabled) ||
      isAdmin;
    if (returnWithoutFees) return CurrencyHelper.formatCurrency(event.CurrencySymbol, amountInt);
    return `${CurrencyHelper.formatCurrency(event.CurrencySymbol, FeeHelper.getAmountWithFees(event, amountInt))}${event.AbsorbFee ? '' : '*'}`;
  }

  public static getServiceFeeMessage(isAdmin: boolean, event: IEvent) {
    if (event.AbsorbFee) return null;
    const isMemberWithRequestingEnabled =
      event.Organisation &&
      event.Organisation.MembershipsEnabled &&
      event.Organisation.IsMember &&
      event.RequestingEnabled;

    if (isAdmin)
      return (
        <div className="service-fee-message">
          Prices shown exclude fees for admin users. If you choose to pay online via Seaty at checkout, a{' '}
          {event.CardFeePercentage}% (min. {CurrencyHelper.formatCurrency(event.CurrencySymbol, event.CardFeeMinimum)})
          will be added.
        </div>
      );

    if (isMemberWithRequestingEnabled)
      return <div className="service-fee-message">Request tickets free, or pay online with service fees.</div>;

    return (
      <div className="service-fee-message">
        * Prices shown include fees.
        {/* <br />
        as outlined in our <a href="https://seaty.co.uk/Docs/TermsOfService">Terms of Service</a>. */}
        {/* {(event.HandlingFee && event.HandlingFee > 0) ||
                      (event.HandlingFeePercentage && event.HandlingFeePercentage > 0)
                        ? 'and handling '
                        : ''}
                      . */}
      </div>
    );
  }
}
