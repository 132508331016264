export interface IMajorFeature {
  title: string;
  subtitle: string;
  intro: string;
  summary: string;
  features: {
    title: string;
    description: string;
  }[];
}

const seatyFeatures: IMajorFeature[] = [
  {
    title: 'Your Event’s Home',
    subtitle: 'Event landing pages, everything your audience needs – all in one place.',
    intro: `Every event on Seaty gets its own beautiful, customisable landing page. This is your event’s online home and the one link to share, promote, and sell tickets with ease.

    Your page works on mobile and desktop, and is automatically available at: Seaty.co.uk/EventTag

    Simple to share, easy to remember.`,
    summary:
      'Every event gets a custom landing page with key details, ticket sales, and easy sharing options. Your one-stop shop for attendees.',
    features: [
      {
        title: 'A Branded Home for Your Event',
        description:
          'Add your own poster and create a unique URL for your event. It’s easy to share and instantly recognisable to your audience.',
      },
      {
        title: 'All Your Event Info, All in One Place',
        description:
          'From descriptions and show details to cast lists, awards, and highlights. Your page becomes the go-to hub for everything attendees need to know.',
      },
      {
        title: 'Built-In Ticket Sales',
        description: 'Whether you’re using seated or general admission, attendees can book directly from your page.',
      },
      {
        title: 'Smart Sharing & Member Credit',
        description:
          'Seaty tracks who’s spreading the word. Logged-in members get automatic credit via share buttons. Great for companies who rely on cast or group.',
      },
      {
        title: 'Venue Info & Marketing Tools',
        description: 'Includes interactive maps, parking tips, and marketing sign-ups that help attendees.',
      },
    ],
  },
  {
    title: 'Seating Plans & Allocated Seating',
    subtitle: 'A seamless way to manage seated and reserved tickets.',
    intro: `Give your attendees the best seat-buying experience with Seaty’s interactive seating plans. Whether you need a simple layout or a detailed venue map, Seaty simplifies designing, managing, and selling allocated seating.

  Real-time availability ensures smooth bookings, while mobile-friendly navigation helps attendees effortlessly find their ideal seat.`,
    summary:
      'Design your perfect seating plan and let attendees pick their own seats with an intuitive, real-time booking system.',

    features: [
      {
        title: 'Build Your Perfect Seating Plan',
        description:
          'Seaty’s intuitive seating plan editor makes it easy to create a layout that works for your venue. Start from scratch or use an existing plan.',
      },
      {
        title: 'Sections for Easy Navigation',
        description:
          'For larger venues, break up your seating plan into sections such as Stalls, Grand Circle, or Balcony. Attendees can easily switch between sections.',
      },
      {
        title: 'Live up-to-date Availability',
        description: 'Attendees always see the latest availability, whether they’re browsing on desktop or mobile.',
      },
      {
        title: 'Mobile-Friendly & Responsive',
        description:
          'All seating plans are designed to work smoothly on any device, making ticket selection just as easy on a phone as on a computer.',
      },
      {
        title: 'Fully Customisable Layouts',
        description: 'Add a stage, aisles, stairways, or even specific features like VIP areas and accessible seating.',
      },
    ],
  },
  {
    title: 'General Admission Tickets',
    subtitle: 'Simple, fast, and flexible ticketing for any event.',
    intro: `Not every event needs assigned seating. With Seaty’s General Admission ticketing, you can sell tickets quickly and efficiently while keeping the process simple for both organisers and attendees.

  Whether it’s a festival, standing-room concert, or flexible event, Seaty simplifies ticketing.`,
    summary: 'Sell flexible, non-allocated tickets with full control over capacities, categories, and pricing.',
    features: [
      {
        title: 'Fast & Flexible Ticketing',
        description:
          'Skip the seat maps and just set your quantities, categories, and pricing. Seaty handles the rest.',
      },
      {
        title: 'Multiple Ticket Types',
        description: 'Create tiers like standard, VIP, early bird, or group offers. All managed from your dashboard.',
      },
      {
        title: 'Live Availability & Sales Tracking',
        description:
          'Stock updates in real-time as tickets sell. No overselling, and full visibility on sales and check-ins.',
      },
      {
        title: 'Simple for Attendees',
        description: 'Buyers just pick how many tickets they need with no seat selection and no fuss.',
      },
      {
        title: 'Track & Manage Attendance',
        description: 'Get real-time reports on ticket sales and attendance.',
      },
    ],
  },
  {
    title: 'Seaty in Your Pocket',
    subtitle: 'The Seaty app, all you need, on the go.',
    intro: `The Seaty app is your on-the-go toolkit for managing and attending events. Whether you’re scanning tickets at the door or checking your seat before the curtain rises, the app keeps things smooth, simple, and in sync.`,
    summary:
      'Our app keeps you connected – view tickets, track sales, approve requests, and even scan tickets for entry.',
    features: [
      {
        title: 'View & Open Tickets Anytime',
        description: 'No need to print or search for emails. Your e-tickets are accessible from your phone.',
      },
      {
        title: 'See Seat Numbers & Notes',
        description: 'Attendees can view where they’re sitting and check any notes added to their tickets.',
      },
      {
        title: 'Approve or Deny Ticket Requests',
        description:
          'Manage your ticket requests from anywhere on the Seaty app. Making it easy to approve requests as they come in.',
      },
      {
        title: 'Quick Event Overview',
        description: 'See real-time day by day breakdown of sales and request data in a glance.',
      },
      {
        title: 'Scan Tickets for Entry',
        description: 'Use your phone camera to scan QR codes at the door – no special equipment needed.',
      },
      {
        title: 'Check Orders on the Go',
        description:
          'Scan a ticket to view the full order instantly, we will let you know if it is valid, or if it has already been scanned.',
      },
    ],
  },
  {
    title: 'Ticket Requests',
    subtitle: 'A flexible way to get tickets into the right hands.',
    intro: `Selling tickets through performers, parents or group members?

  Seaty’s Ticket Request System is perfect for schools, amateur theatres, clubs, and community groups where tickets are distributed by people – not box offices.

  We give you all the tools you need to track money owed, and manage it all online or on the go.`,
    summary:
      'Allow attendees to request seats rather than buy them outright, giving you control over who gets what before payment is taken.',
    features: [
      {
        title: 'Attendees Can Request Tickets',
        description:
          'Attendees, members or representatives can log in and request the exact seats or number of tickets they need – no paperwork required.',
      },
      {
        title: 'Approve or Deny with a Click',
        description:
          'You’re always in control. Each request is reviewed and approved by an organiser before tickets are issued.',
      },
      {
        title: 'Ticket Notes',
        description:
          'Attendees and event admins can leave notes on ticket requests and orders that can be edited at any time.',
      },
      {
        title: 'Manage from Web or Phone App',
        description: 'Handle request approvals wherever you are. Even side-stage or in the school hall!',
      },
    ],
  },
  {
    title: '“Money Talks” Balances & Payments',
    subtitle: 'Payment tracking built right into your ticketing system.',
    intro: `Once a ticket request is approved, or a ticket order is put through by an admin without payment, Seaty helps you track exactly what’s owed and how it’s paid.

  Whether you collect in cash, take bank transfers, or let attendees pay online, every payment is logged and balanced in real-time.`,
    summary:
      'Track outstanding balances, log payments made outside Seaty, and let attendees settle their dues online anytime.',
    features: [
      {
        title: 'Track All Payments in One Place',
        description: 'Online payments to cash and cheques, everything is recorded and organised in Seaty.',
      },
      {
        title: 'Send Statements to Attendees',
        description: 'Admins can send easy-to-read balance summaries to attendees.',
      },
      {
        title: 'Flexible Logging Tools',
        description: 'Record payments even if they’re partial, staggered, or across multiple orders.',
      },
      {
        title: 'Balance Dashboard for Organisers',
        description: 'Know who owes what at a glance, without chasing spreadsheets.',
      },
    ],
  },
  {
    title: 'Smarter Messaging with Mailshots',
    subtitle: 'Mailshots make communications simple, smart, and fully built-in.',
    intro: `Keeping in touch with your attendees, team members, or wider audience has never been easier. With Mailshots, you can send emails directly from your Seaty dashboard with no extra tools or subscriptions needed.

  From last-minute updates to post-show surveys and everything in between, Mailshots gives you the power to communicate clearly and quickly, all in one place.`,
    summary:
      'Send emails to attendees, members, or admins directly through Seaty, keeping everyone informed without the hassle.',
    features: [
      {
        title: 'Send the Message to the Right People',
        description: 'Target attendees, members, admins, or mailing lists with focused, relevant messages.',
      },
      {
        title: 'AI Writing Help',
        description: 'Seaty’s AI helps you draft messages using your event details keeping them quick and on-brand.',
      },
      {
        title: 'Organised by Type',
        description: 'Label messages as Updates, Marketing, or Surveys so people only get what they expect.',
      },
      {
        title: 'Drafts & History',
        description: 'Save drafts, track what’s been sent, and search your message history with ease.',
      },
      {
        title: 'GDPR Friendly',
        description: 'Built-in opt-outs and smart targeting keep your messaging compliant and respectful.',
      },
    ],
  },
  {
    title: 'More Than Tickets',
    subtitle: 'Sell merch, programmes, and extras all in one seamless system.',
    intro: `Want to offer programmes, t-shirts, or other extras at your event? With Seaty, you can sell merchandise the same way you sell tickets – all through your event page.

  Attendees get a digital voucher they can exchange at the event, and organisers can scan and manage these just like any ticket.

  This feature is perfect for theatre programmes, merch tables, drinks vouchers, and more. All tracked and managed inside Seaty.`,
    summary:
      'Offer merchandise and extras like programmes and T-shirts. With a voucher system for easy collection – just like tickets.',
    features: [
      {
        title: 'Add Extras in Seconds',
        description:
          'Set up merchandise, programmes, or add-ons just like tickets with no separate tools needed. Everything lives in one system.',
      },
      {
        title: 'Scannable Vouchers for Every Item',
        description:
          'Attendees receive a clear, digital voucher for each extra they purchase. Just like tickets, these can be printed or shown on a phone.',
      },
      {
        title: 'Fast, Reliable Collection',
        description:
          'Scan vouchers on the day using the Seaty app. Perfect for quick, paper-free pick-up at merch stands or box office desks.',
      },
      {
        title: 'Plan with Confidence',
        description:
          'Real-time reports show you exactly how many extras have been sold, so you’ll know what to bring and avoid over-ordering.',
      },
    ],
  },
  {
    title: 'Flexible Discounts',
    subtitle: 'Encourage group bookings, reward loyal fans, or create special offers.',
    intro: `Discounts aren’t just a bonus – they’re a powerful way to drive sales, reward loyalty, and encourage group bookings.

  With Seaty, you can create flexible, customisable discounts that fit your event, your audience, and your goals. Whether it’s a promo code or an automatic offer, we make it simple for organisers and seamless for attendees.`,
    summary:
      'Encourage sales with customisable discounts – apply them automatically or let attendees enter a code at checkout.',
    features: [
      {
        title: 'Smart Discounts, Your Way',
        description: 'Create offers that reward loyalty, boost group bookings, or drive early sales.',
      },
      {
        title: 'Automatic or Code-Based',
        description:
          'Apply discounts instantly when conditions are met, or use promo codes for more control at checkout.',
      },
      {
        title: 'Targeted & Customisable',
        description:
          'Set criteria like ticket type, quantity, or event date to trigger your discounts exactly how you want.',
      },
      {
        title: 'Flexible Rewards',
        description:
          'Choose the outcome with a percentage off, a fixed saving, or free tickets – based on what works best.',
      },
      {
        title: 'Smooth & Transparent Checkout',
        description: 'Discounts are clearly shown in the order summary and confirmation.',
      },
    ],
  },
  {
    title: 'Power to Your Members',
    subtitle: 'Built for groups, casts, schools and companies who sell together.',
    intro: `With Seaty, you can set up your organisation and add members. Ideal for theatre companies, schools, or any group where sales come from the people involved.

  Members aren’t just names on a list. They become part of your sales toolkit, with built-in permissions, tools, and performance tracking.

  Perfect for teams where members are the box office. Seaty keeps it fair, accountable, and easy to manage.`,
    summary:
      'Turn cast members, committee members, or students into your sales team by tracking their sales and setting quotas.',
    features: [
      {
        title: 'Add Members & Member Types',
        description: 'Create and assign different types of members (e.g. cast, committee, students).',
      },
      {
        title: 'Track Individual Sales',
        description: 'See who’s sold what. Seaty gives real-time sales reports by member.',
      },
      {
        title: 'Set Sales Quotas',
        description: 'Assign ticket quotas by member type and track progress with clear reports.',
      },
      {
        title: 'Member-Only Requests',
        description: 'Restrict ticket requests to members only. Member requests can be auto accepted.',
      },
    ],
  },
  {
    title: 'Event Attendees',
    subtitle: 'A better experience for your audience.',
    intro: `Seaty isn’t just about giving you powerful tools – it also makes life easier for your attendees. From viewing bookings and updating ticket notes to paying balances and sharing tickets, they’re in control from the moment they order.

  With seamless mobile access and self-service features, Seaty helps your audience stay organised and reduces the admin load on you.`,
    summary:
      'Seaty isn’t just for organisers – attendees can manage their tickets, share them, add notes, and pay balances all in one place.',
    features: [
      {
        title: 'Everything in One Place',
        description:
          'Attendees can view all tickets, past and present, in one spot. Easy access any time, on any device.',
      },
      {
        title: 'Personalised & Shareable Tickets',
        description:
          'Custom notes help track who each ticket is for, and sharing with family or friends is quick and seamless.',
      },
      {
        title: 'Mobile-Friendly Tools',
        description: 'With the Seaty app, attendees can manage tickets, update notes, and access everything on the go.',
      },
      {
        title: 'Pay Balances Anytime',
        description: 'Outstanding payments? Attendees can check what’s due and pay securely online, without chasing.',
      },
      {
        title: 'No More Lost Tickets',
        description: 'Tickets can be reprinted or retrieved any time. Digital or paper, they’re always accessible.',
      },
    ],
  },
  {
    title: 'Reporting That Drives Action',
    subtitle: 'Real-time reporting to stay on top of every sale, seat, and stat.',
    intro: `Seaty gives you a clear view of how your event is performing – as it happens. Whether you’re selling online or offline, tracking member quotas, or managing complex seating plans, your data is always up to date and easy to understand.

  With built-in tools for financial tracking, sales overviews, and attendee insight, Seaty keeps you informed and in control from the first ticket to the final report.`,
    summary:
      'Track ticket sales, payments, member performance and more – with clear dashboards and exportable reports at your fingertips.',
    features: [
      {
        title: 'Live Sales Dashboards',
        description:
          'Get an overview of ticket sales, revenue, and attendance. Broken down by ticket type, date, payment method, or seat section.',
      },
      {
        title: 'Payment Summaries & Balances',
        description:
          'See what’s been paid and what’s still outstanding. With order breakdowns and offline payment logging, know what’s owed.',
      },
      {
        title: 'Downloadable Reports',
        description:
          'Export your event data to Excel for deeper analysis or sharing. It’s easy to filter by date, member, ticket type and more.',
      },
      {
        title: 'Event Comparison Reports',
        description:
          'See how your events stack up. Select past events and compare sales performance over time. Perfect for understanding trends.',
      },
    ],
  },
  {
    title: 'Ask The Right Questions',
    subtitle: 'Custom questions to collect the info you need before the curtain rises.',
    intro: `Seaty makes it easy to gather important attendee information when people book tickets. Whether it’s dietary requirements, accessibility needs, t-shirt sizes, or school group names, you can tailor your event’s question forms to suit any occasion.

  Answers are automatically stored with each order, so everything is easy to access and manage.`,
    summary: 'Collect the details you need from attendees with custom forms built right into your booking flow.',
    features: [
      {
        title: 'Flexible Question Types',
        description:
          'Choose from text fields, dropdowns, multiple choice, and more. Whatever suits the data you’re looking to collect.',
      },
      {
        title: 'Attach to Any Ticket Type',
        description:
          'Ask questions on a per-ticket basis or just once per order. Tailor your forms based on ticket category, event date, or attendee type.',
      },
      {
        title: 'Seamlessly Integrated',
        description:
          'Questions appear right in the booking flow and are included in admin order views, reports, and exports with no copy-pasting required.',
      },
      {
        title: 'Simple and Easy Setup',
        description:
          'Add, remove or edit your questions anytime through your Seaty dashboard. It doesn’t require any technical setup.',
      },
    ],
  },
  {
    title: 'Transparent Fees',
    subtitle: 'We believe in keeping things simple.',
    intro: `With Seaty’s online payments, you decide how fees are handled. Absorb them into your ticket price for a clean, all-in-one cost, or pass them on at checkout to keep your payout unchanged. Either way, everything is clearly displayed with no surprises.`,
    summary:
      'No setup costs, no hidden charges – pay only when using Seaty’s online payments, or log and track offline sales for free.',
    features: [
      {
        title: 'Online Card Payments (via Stripe)',
        description:
          'Seaty uses Stripe for secure card payments with a simple flat fee per transaction. You choose whether to include it in the ticket price or add it at checkout. Fees are always shown with no hidden charges.',
      },
      {
        title: 'Free for Your Box Office',
        description:
          'If you’re taking payments outside Seaty, such as in person, by phone, or via bank transfer, you can still book and manage tickets at no cost. You’ll get full access to seating plans, order tracking, attendee tools, and admin features, completely free.',
      },
      {
        title: 'No Setup Fees or Subscriptions',
        description:
          'It’s free to create events, build seating plans, and start selling. You only pay if you use Seaty’s online payment system.',
      },
      {
        title: 'Track Offline Payments with Ease',
        description:
          'Even if you collect payments outside Seaty, you can still log them, generate statements, and track balances – all without a spreadsheet.',
      },
      {
        title: 'Payouts After Your Event',
        description:
          'For security and peace of mind, Seaty holds funds until your event ends. Payouts are typically made within four working days.',
      },
      {
        title: 'Mix Online & Offline Payments',
        description:
          'Combine card payments and offline methods in a single event. Seaty keeps everything tracked in one place, with full visibility for each attendee.',
      },
    ],
  },
  {
    title: 'Made in Britain',
    subtitle: 'Proudly built for UK organisations and events.',
    intro: `Seaty is a fully UK-based limited company, founded in the Midlands with one goal. To make ticketing easier for schools, theatre companies, charities and community events across the country.

  Seaty is more than just a platform. We are part of the same community as you. Whether you’re selling tickets for a school play, a charity gala, or a packed-out panto, Seaty is here to help you do it smoothly, professionally and confidently.`,
    summary:
      'Seaty is UK-based, built to support theatres, schools, charities, and event organisers, while complying with UK laws and regulations.',
    features: [
      {
        title: 'UK Company, UK Focus',
        description:
          'We’re proudly registered in the United Kingdom and built specifically for UK users, events, and audiences.',
      },
      {
        title: 'Fully GDPR Compliant',
        description: 'All personal data is handled in accordance with UK data protection regulations and privacy law.',
      },
      {
        title: 'In Line with UK Online Practice Standards',
        description:
          'We follow all required consumer protections and digital service standards, from payments to refunds.',
      },
      {
        title: 'Terms That Put You First',
        description:
          'Our Terms of Service are clear, up to date, and designed to meet the real-world needs of both event organisers and attendees.',
      },
      {
        title: 'Support That Understands Your World',
        description: 'No outsourced bots or faraway call centres – just real people ready to help you succeed.',
      },
    ],
  },
];

export default seatyFeatures;
