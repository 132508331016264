import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AdminMenuOrganisationModal from './components/AdminMenuOrganisationModal';
import AdminMenuTourModal from './components/AdminMenuTourModal';
import CookieHelper from './helpers/CookieHelper';
import LocationHelper from './helpers/LocationHelper';
import UserHelper from './helpers/UserHelper';
import './less/style.scss';
import { OrderDashboardModal } from './modals/AdminOrderModal';
import CookieModal from './modals/CookieModal';
import OrderSearchModal from './modals/OrderSearchModal';
import { ResetPasswordModal } from './modals/ResetPasswordModal';
import { StripeConnectModal } from './modals/StripeConnectModal';
import { VerifyUserModal } from './modals/VerifyUserModal';
import AdminPage from './views/Admin/AdminPage';
import AdminSections, { AdminSection } from './views/Admin/AdminSections';
import AttendeeStatementPage from './views/AttendeeStatementPage';
import BlankTicketsPage from './views/BlankTicketsPage';
import BookingPage from './views/EventPage/BookingPage';
import EventLandingPage from './views/EventPage/EventLandingPage';
import FeesPage from './views/Fees/FeesPage';
import HomePage from './views/HomePage/HomePage';
import MoreInfoPage from './views/MoreInfo/MoreInfoPage';
import MyTicketsPage from './views/MyTickets/MyTicketsPage';
import OrganisationLandingPage from './views/OrganisationPage/OrganisationLandingPage';
import TicketsPage from './views/TicketsPage';
import TourLandingPage from './views/TourPage/TourLandingPage';

import { useRecoilState } from 'recoil';
import eventMenuState from './atoms/eventMenuState';
import loaderState from './atoms/loaderState';
import orderSearchState from './atoms/orderSearchState';
import orderState from './atoms/orderState';
import organisationMenuState from './atoms/organisationMenuState';
import tourMenuState from './atoms/tourMenuState';
import wizardEventState from './atoms/wizardEventState';
import wizardOrganisationState from './atoms/wizardOrganisationState';
import wizardTourState from './atoms/wizardTourState';
import Loader from './components/Loader';
import GAHelper from './helpers/GAHelper';
import WizardEvent, { EventWizardSection } from './views/Admin/WizardEvent';
import WizardOrganisation, { OrganisationWizardSection } from './views/Admin/WizardOrganisation';
import WizardTour, { TourWizardSection } from './views/Admin/WizardTour';
import ISidebarItem from './views/Docs/ISidebarItem';
import sidebarItems from './views/Docs/SidebarItems';
import AdminMenuEventModal from './components/AdminMenuEventModal';
import StripeCheckoutCompletePage from './components/stripe/StripeCheckoutCompletePage';
import StripePaymentPage from './components/stripe/StripePaymentPage';
import StripePaymentCompletePage from './components/stripe/StripePaymentCompletePage';
import SearchPage from './views/SearchPage/SearchPage';
import { Helmet } from 'react-helmet-async';
import EmailPreferencesPage from './views/Docs/EmailPreferencesPage';
import UserPage from './views/Admin/UserPage';
import FeaturesPage from './views/Features/FeaturesPage';

const Application: React.FC = () => {
  const [loaders] = useRecoilState(loaderState);

  const [tourMenu, setTourMenu] = useRecoilState(tourMenuState);
  const [organisationMenu, setOrganisationMenu] = useRecoilState(organisationMenuState);
  const [eventMenu, setEventMenu] = useRecoilState(eventMenuState);

  const [order, setOrder] = useRecoilState(orderState);
  const [orderSearch, setOrderSearch] = useRecoilState(orderSearchState);
  const [wizardOrganisation, setWizardOrganisation] = useRecoilState(wizardOrganisationState);
  const [wizardTour, setWizardTour] = useRecoilState(wizardTourState);
  const [wizardEvent, setWizardEvent] = useRecoilState(wizardEventState);

  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  const [cookiesRefused, setCookiesRefused] = useState(false);
  const [initialised, setInitialised] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.setTimeout(() => {
      var botPattern =
        '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
      var re = new RegExp(botPattern, 'i');
      var userAgent = navigator.userAgent;

      setCookiesAccepted(CookieHelper.cookiesEnabled || re.test(userAgent));
    }, 500);
  }, []);

  React.useEffect(() => {
    // Code to run when the location changes
    if (GAHelper.initialised) {
      GAHelper.visit(location.pathname + location.search);
    }
  }, [location]);

  useEffect(() => {
    if (!UserHelper.currentUser && !UserHelper.guestUser) UserHelper.load().finally(() => setInitialised(true));
    GAHelper.init();
    GAHelper.visit(window.location.pathname + window.location.search);
  }, []);

  if (!initialised) return null;

  const getSidebarRoute = (item: ISidebarItem) => {
    if (item.component) {
      return <Route key={item.url} element={item.component} path={item.url} />;
    } else if (item.items && item.items.length > 0) {
      return (
        <React.Fragment key={`${item.url}${item.name}`}>{item.items.map((i) => getSidebarRoute(i))}</React.Fragment>
      );
    }
  };

  const showCookieModal =
    !cookiesRefused && !cookiesAccepted && !window.location.href.toLowerCase().includes('docs/cookies');

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://seaty.co.uk" />
        <meta property="fb:app_id" content="747095462055934" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Seaty" />
        <meta
          property="og:description"
          content="The simple, free online box office. Sign up and let us help you sell your tickets!"
        />
        <meta
          property="description"
          content="The simple, free online box office. Sign up and let us help you sell your tickets!"
        />
        <meta
          property="og:image"
          content="https://seatylive.blob.core.windows.net/images/Seaty_Lets_Talk_Tickets.png"
        />

        <title>Seaty - A Simple Box Office Platform</title>
      </Helmet>

      {/* <Route path="/.well-known/apple-developer-merchantid-domain-association" onEnter={reload} /> */}
      <React.Suspense fallback={<>...</>}>
        <Routes>
          <Route element={<HomePage />} path={'/'} />
          <Route element={<MoreInfoPage />} path="/Home/MoreInfo" />
          <Route element={<FeaturesPage />} path="/Home/Features" />
          <Route element={<FeesPage />} path="/Home/Fees" />
          <Route element={<EmailPreferencesPage />} path="/Emails/Manage" />

          {sidebarItems.map((item) => getSidebarRoute(item))}

          <Route element={<SearchPage />} path="/Search/:query" />
          <Route element={<TicketsPage />} path="/Order/Tickets/:ticketGuid" />
          <Route element={<TicketsPage />} path="/Order/AllTickets" />

          <Route element={<TourLandingPage />} path="/Tour/:tourTag" />
          <Route element={<OrganisationLandingPage />} path="/Organisation/:organisationTag" />

          <Route
            element={<AdminPage />}
            path={`/Organisation/:organisationTag/Event/:eventTag/${AdminSection.Scans}/:eventDate/:eventTime`}
          />

          <Route element={<AdminPage />} path={`/Organisation/:organisationTag/Event/Create`} />

          {AdminSections.getEventSections().map((s) => (
            <React.Fragment key={s.section}>
              <Route
                key={s.section}
                element={<AdminPage />}
                path={`/Organisation/:organisationTag/Event/:eventTag/${s.section}`}
              />

              {s.sections?.map((subsection) => (
                <Route
                  key={subsection.section}
                  element={<AdminPage />}
                  path={`/Organisation/:organisationTag/Event/:eventTag/${s.section}/${subsection.section}`}
                />
              ))}
            </React.Fragment>
          ))}

          {AdminSections.getTourSections().map((s) => (
            <Route
              key={s.section}
              element={<AdminPage />}
              path={`/Organisation/:organisationTag/Tour/:tourTag/${s.section}`}
            />
          ))}

          {AdminSections.getUserSections().map((s) => (
            <React.Fragment key={s.section}>
              <Route key={s.section} element={<UserPage />} path={`/User/${s.section}`} />
              {s.sections?.map((subsection) => (
                <Route key={subsection.section} element={<UserPage />} path={`/User/${subsection.section}`} />
              ))}
            </React.Fragment>
          ))}

          {AdminSections.getOrganisationSections().map((s) => (
            <React.Fragment key={s.section}>
              <Route key={s.section} element={<AdminPage />} path={`/Organisation/:organisationTag/${s.section}`} />
              {s.sections?.map((subsection) => (
                <Route
                  key={subsection.section}
                  element={<AdminPage />}
                  path={`/Organisation/:organisationTag/${subsection.section}`}
                />
              ))}
            </React.Fragment>
          ))}

          {AdminSections.getEventDateSections().map((s) => (
            <Route
              key={s.section}
              element={<AdminPage />}
              path={`/Organisation/:organisationTag/Event/:eventTag/Date/:eventDate/:eventTime/${s.section}`}
            />
          ))}

          <Route
            element={<StripeCheckoutCompletePage />}
            path="/:eventTag/tickets/:eventDate/:eventTime/CompleteOrder/:purchaseId"
          />
          <Route element={<StripePaymentPage />} path="/:eventTag/InitialisePayment/:purchaseId/:clientId" />
          <Route element={<StripePaymentCompletePage />} path="/:eventTag/CompletePayment/:purchaseId" />
          <Route element={<BlankTicketsPage />} path="/:eventTag/BlankTickets" />
          <Route element={<AttendeeStatementPage />} path="/:eventTag/AttendeeStatement" />

          <Route element={<BookingPage />} path="/:eventTag/tickets/:eventDate/:eventTime" />
          <Route element={<TicketsPage />} path="/:eventTag/SampleTickets/:eventDate/:eventTime" />
          <Route element={<EventLandingPage />} path="/:eventTag" />
        </Routes>
      </React.Suspense>
      {wizardEvent && (
        <WizardEvent
          startFrom={wizardEvent.startFrom ? wizardEvent.startFrom : EventWizardSection.EventOptions}
          organisation={wizardEvent.organisation}
          tour={wizardEvent.tour}
          onClose={() => {
            if (wizardEvent.onClose) wizardEvent.onClose();
            setWizardEvent(null);
          }}
        />
      )}
      {wizardTour && (
        <WizardTour
          startFrom={TourWizardSection.NewTour}
          organisation={wizardTour.organisation}
          onClose={() => {
            if (wizardTour.onClose) wizardTour.onClose();
            setWizardTour(null);
          }}
        />
      )}
      {wizardOrganisation && (
        <WizardOrganisation
          startFrom={
            wizardOrganisation.createNew
              ? OrganisationWizardSection.NewOrganisation
              : OrganisationWizardSection.OrganisationSelector
          }
          onClose={() => {
            if (wizardOrganisation.onClose) wizardOrganisation.onClose();
            setWizardOrganisation(null);
          }}
        />
      )}
      {order && (
        <OrderDashboardModal
          onClose={() => {
            if (order.onClose) order.onClose();
            setOrder(null);
          }}
          event={order.event}
          orderId={order.orderId}
        />
      )}
      {orderSearch && <OrderSearchModal onClose={() => setOrderSearch(null)} event={orderSearch} />}
      {eventMenu && (
        <AdminMenuEventModal
          eventTag={eventMenu.eventTag}
          eventDate={eventMenu.eventDate}
          onCloseClick={() => {
            setEventMenu(null);
          }}
        />
      )}
      {organisationMenu && (
        <AdminMenuOrganisationModal
          organisation={organisationMenu}
          onCloseClick={() => {
            setOrganisationMenu(null);
          }}
        />
      )}
      {tourMenu && (
        <AdminMenuTourModal
          tourTag={tourMenu.TourTag}
          onCloseClick={() => {
            setTourMenu(null);
          }}
        />
      )}
      {LocationHelper.getQueryVariable('resetGuid') && (
        <ResetPasswordModal onClose={() => window.location.replace(window.location.origin)} />
      )}
      {LocationHelper.getQueryVariable('verifyGuid') && (
        <VerifyUserModal onClose={() => window.location.replace(window.location.origin)} />
      )}
      {LocationHelper.getQueryVariable('state') && (
        <StripeConnectModal onClose={() => window.location.replace(window.location.origin)} />
      )}
      {loaders && loaders.length > 0 && <Loader>{loaders[0].message}</Loader>}

      {showCookieModal && (
        <CookieModal
          onAccept={() => {
            setCookiesAccepted(true);
            CookieHelper.cookiesEnabled = true;
          }}
          onRefuse={() => setCookiesRefused(true)}
        />
      )}
    </>
  );
};

export default Application;
