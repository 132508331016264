import React, { FunctionComponent } from 'react';
import { LazyImage } from '../../components/LazyImage';
import DateHelper from '../../helpers/DateHelper';
import SVGHelper from '../../helpers/SVGHelper';
import { IOrganisation } from '../../interfaces/IOrganisation';
import ReactQuill from 'react-quill';
import EventList from '../../components/EventList';
import linq from 'linq';
import moment from 'moment';
import Constants from '../../helpers/Constants';
import ShareLinks from '../EventPage/ShareLinks';

export interface IProps {
  organisation: IOrganisation;
  hideBorder?: boolean;
}

const OrganisationDetails: FunctionComponent<IProps> = (props) => {
  const organisation = props.organisation;

  if (!organisation) return;

  const events = linq
    .from(organisation.Events)
    .where((event) => {
      // const now = event && moment.utc().tz(event.TimeZoneIana);
      // const onlineFrom = event && moment.utc(event.OnlineFromDate).tz(event.TimeZoneIana);
      // const salesStarted = event && onlineFrom <= now;

      const isCurrent =
        moment.utc(event.MaxDate).tz(event.TimeZoneIana) >
        moment
          .utc()
          .tz(event.TimeZoneIana)
          .add(0 - Constants.DaysOldEventsShouldBeVisibleToPublic, 'days');

      return isCurrent;
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .groupBy((e) => e.TourId)
    .selectMany((e) => {
      const event = e.first();
      if (event.TourId) {
        return [{ ...e.first(), MinDate: event.MinDate, MaxDate: e.last().MaxDate }];
      } else {
        return e.toArray();
      }
    })
    .orderBy((e) => moment.utc(e.MinDate).unix())
    .toArray();

  return (
    <>
      <div
        className="strip-block"
        style={props.hideBorder ? { border: 0, background: 'white' } : { background: 'white' }}
      >
        <div className="row">
          <div className="col-sm-5 left">
            {organisation.LogoUrl && (
              <LazyImage style={{ display: 'inline-block' }} alt="Organisation" src={organisation.LogoUrl} />
            )}
          </div>

          <div className="col-sm-7">
            {organisation && organisation.IsMember && (
              <>
                <>You're a member! 🧑‍🤝‍🧑</>
                <div style={{ marginBottom: '30px' }} className="text">
                  Your user account is recognised as a member of {organisation.Name}.
                </div>
              </>
            )}

            <div style={{ marginBottom: '30px' }}>
              Share this organisation
              <ShareLinks organisationTag={organisation.OrganisationTag} />
            </div>

            {organisation.Name}

            <div className="text" style={{ marginBottom: '30px' }}>
              {organisation.Description && (
                <ReactQuill
                  theme="snow"
                  readOnly={true}
                  modules={{ toolbar: false }}
                  value={organisation.Description}
                />
              )}

              {organisation.WebsiteUrl && (
                <div style={{ marginBottom: '15px' }}>
                  <a
                    href={`${organisation.WebsiteUrl.toLowerCase().includes('http') ? '' : 'http://'}${organisation.WebsiteUrl}`}
                  >
                    {organisation.WebsiteUrl}
                  </a>
                </div>
              )}

              {organisation.Awards != null && organisation.Awards.length > 0 && (
                <div className="awards">
                  {organisation.Awards.map((award, index) => (
                    <div className="award" key={`Award_${index}`}>
                      <img src={SVGHelper.get('Trophy')} />

                      <div className="award-tooltip animated fadeIn">
                        <div className="date">{DateHelper.asDateMonthYear(award.Date)}</div>
                        <div className="name">{award.Description}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {(organisation.BoxOfficeEmail || organisation.BoxOfficeTel) && (
              <div style={{ marginBottom: '30px' }}>
                Box office
                <div className="text">
                  {organisation.BoxOfficeEmail && (
                    <div style={{ marginTop: '2px' }}>
                      <a href={`mailto:${organisation.BoxOfficeEmail}`} target="_top">
                        {organisation.BoxOfficeEmail}
                      </a>
                    </div>
                  )}

                  {organisation.BoxOfficeTel && <div style={{ marginTop: '2px' }}>{organisation.BoxOfficeTel}</div>}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {organisation.PersonnelGroups &&
        organisation.PersonnelGroups.length > 0 &&
        organisation.PersonnelGroups.map((personnelGroup) => (
          <React.Fragment key={personnelGroup.Id}>
            <div className="strip-block personnel-strip-block">
              {personnelGroup.Name}

              <div className="personnel-group-view">
                {personnelGroup.Personnel &&
                  personnelGroup.Personnel.length > 0 &&
                  personnelGroup.Personnel.map((personnel) => (
                    <React.Fragment key={personnel.Id}>
                      <div className="personnel-view">
                        <div className="image">
                          <img src={personnel.ImageUrl}></img>
                        </div>
                        {personnel.Name && personnel.Name.length > 0 && (
                          <label className="personnel-name">{personnel.Name}</label>
                        )}
                        {personnel.Role && personnel.Role.length > 0 && (
                          <label className="personnel-role">{personnel.Role}</label>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </React.Fragment>
        ))}

      {events.length > 0 && (
        <div className="strip-block events-list-strip-block">
          Upcoming events {/*<span style={{ color: '#625afa' }}>#{organisation.OrganisationTag.toUpperCase()}</span> */}
          <EventList events={events} />
        </div>
      )}
    </>
  );
};

export default OrganisationDetails;
