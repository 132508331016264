import { useWindowSize } from '@react-hook/window-size';
import linq from 'linq';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Selecto from 'react-selecto';
import DeviceHelper from '../../helpers/DeviceHelper';
import SeatingPlanHelper from '../../helpers/SeatingPlanHelper';
import { ISeatingPlan } from '../../interfaces/ISeatingPlan';
import { ISeatingPlanObject } from '../../interfaces/ISeatingPlanObject';
import { ISeatingPlanSeat } from '../../interfaces/ISeatingPlanSeat';
import SVGDragSelect from '../../svg/SVGDragSelect';
import SVGFullScreen from '../../svg/SVGFullScreen';
import SVGInfo from '../../svg/SVGInfo';
import SVGPan from '../../svg/SVGPan';
import SVGPrice from '../../svg/SVGPrice';
import SVGZoomIn from '../../svg/SVGZoomIn';
import SVGZoomOut from '../../svg/SVGZoomOut';
import { ALIGN_CENTER, ALIGN_TOP, TOOL_NONE, TOOL_PAN } from '../../svg_pan_zoom';
import SVGPanZoom from '../../svg_pan_zoom/uncontrolled-viewer';
import { ViewMode } from '../../views/Editor/event/EventEditorPage';
import './SeatingPlan.scss';
import SeatingPlanObject from './SeatingPlanObject';
import SeatingPlanPlaceholder from './SeatingPlanPlaceholder';
import SeatingPlanSeat from './SeatingPlanSeat';
import NumberHelper from '../../helpers/NumberHelper';
import SVGDrag from '../../svg/SVGDrag';
import ZIndexes from '../../ZIndexes';
import { ITicketCategory } from '../../interfaces/ITicketCategory';
import SVGHelper from '../../helpers/SVGHelper';
import moment from 'moment';
import FeeHelper from '../../helpers/FeeHelper';
import { IEvent } from '../../interfaces/IEvent';

export interface ISeatClickArgs {
  control: boolean;
  shift: boolean;
}

export interface ISection {
  Id: number;
  Name: string;
}

export interface IPlaceholder {
  c: number;
  r: number;
}

export interface IProps {
  seatingPlan: ISeatingPlan;
  handleObjectClick?: (object: ISeatingPlanObject) => void;
  onSeatClick?: (seat: ISeatingPlanSeat, args?: ISeatClickArgs) => void;
  onSeatsSelected?: (seats: ISeatingPlanSeat[]) => void;
  fullScreenClick?: () => void;
  onPlaceholdersSelected?: (placeholders: IPlaceholder[]) => void;
  onObjectsSelected?: (objects: ISeatingPlanObject[]) => void;
  onSelection?: (seats: ISeatingPlanSeat[], placeholders: IPlaceholder[], objects: ISeatingPlanObject[]) => void;
  onNothingSelected?: () => void;
  onPricesClick?: () => void;
  onInfoClick?: () => void;
  disabled?: boolean;
  containerWidth?: number;
  containerHeight?: number;
  showOrders?: boolean;
  viewMode: ViewMode;
  moveSeatsLeft?: () => void;
  moveSeatsRight?: () => void;
  moveSeatsUp?: () => void;
  moveSeatsDown?: () => void;
  onPlaceholderClick?: (c, r) => void;
  toolbarButtons?: any;
  dragToSelectByDefault?: boolean;
  onSeatDrag?: (e) => void;
  prices?: ITicketCategory[][];
  verticalPadding?: number;
  event?: IEvent;
  isAdmin?: boolean;
}

let panningTimeout = null;
let clicking: any = null;

enum Tool {
  Drag = 'drag',
  Select = 'select',
  Pan = 'Pan',
}

const SeatingPlanSvg: FunctionComponent<IProps> = (props) => {
  const { seatingPlan, viewMode } = props;

  var elementWidth = SeatingPlanHelper.elementWidth;

  const canSelect =
    props.onSeatsSelected != null ||
    props.onPlaceholdersSelected != null ||
    props.onObjectsSelected != null ||
    props.onSelection != null;
  const addPlaceholders = viewMode == ViewMode.Design;
  const showCategoryColours = !addPlaceholders;

  const Viewer = useRef<SVGPanZoom>(null);
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
  const [controlKeyPressed, setControlKeyPressed] = useState(false);
  const [draggedSeat, setDraggedSeat] = useState(null);
  const [lastAction, setLastAction] = useState(null);

  const [_windowWidth, _windowHeight] = useWindowSize({ initialWidth: 400, initialHeight: 400 });

  const [zoomLevel, setZoomLevel] = useState(1.3);
  const [_tool, setTool] = useState(props.dragToSelectByDefault ? Tool.Select : Tool.Pan);

  const [visibleSeatCategories, setVisibleSeatCategories] = useState([]);

  useEffect(() => {
    setVisibleSeatCategories([]);
  }, [props.seatingPlan]);

  const [visibleArea, setVisibleArea] = useState<{ startY: number; startX: number; endY: number; endX: number }>({
    startY: 0,
    startX: 0,
    endX: 0,
    endY: 0,
  });

  const mobileAndTablet = DeviceHelper.mobileAndTabletCheck();
  const disableOffset = viewMode === ViewMode.Design || viewMode === ViewMode.Label || viewMode === ViewMode.Category;
  const width = props.containerWidth ? props.containerWidth : _windowWidth ? _windowWidth : 500;

  const [h, setH] = useState(window.innerHeight);
  const height = props.containerHeight
    ? props.containerHeight
    : viewMode === ViewMode.Attendee
      ? h - (props.verticalPadding ? props.verticalPadding : 225)
      : 500;

  const tool = shiftKeyPressed ? Tool.Select : _tool;

  const downloadSeatingPlan = () => {
    // Get a reference to the <g> element
    const groupElement = document.querySelector('.svg-pan-transformer');

    // Clone and isolate the <g> element
    const clonedGroup: any = groupElement.cloneNode(true);

    // Get the current transform matrix string
    const currentTransform = clonedGroup.getAttribute('transform');

    // Extract the matrix values from the current transform
    const matrixValues = currentTransform.match(/matrix\(([^,]+),([^,]+),([^,]+),([^,]+),([^,]+),([^,]+)\)/);

    if (matrixValues && matrixValues.length === 7) {
      // Parse the matrix values into numbers
      const a = parseFloat(matrixValues[1]);
      const b = parseFloat(matrixValues[2]);
      const c = parseFloat(matrixValues[3]);
      const d = parseFloat(matrixValues[4]);

      // Set the last two values (e and f) to 0
      const newTransform = `matrix(${a}, ${b}, ${c}, ${d}, 0, 0)`;

      // Set the new transform as the new transform attribute
      clonedGroup.setAttribute('transform', newTransform);
    }

    const textElements = clonedGroup.querySelectorAll('text');
    textElements.forEach((text) => {
      text.setAttribute('fill', 'white');
      text.setAttribute('y', parseFloat(text.getAttribute('y')) + 3);
    });

    // Remove any <image> elements from the cloned group
    const imageElements = (clonedGroup as any).querySelectorAll('image');
    imageElements.forEach((image) => image.remove());

    // Create a new SVG containing just the modified cloned <g> element
    const isolatedSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    isolatedSVG.appendChild(clonedGroup);

    let svgH = height;
    let svgW = width;

    if (groupElement.getClientRects && groupElement.getClientRects().length > 0) {
      svgH = groupElement.getClientRects()[0].height;
      svgW = groupElement.getClientRects()[0].width;
    }

    // Set the width and height attributes of the isolated SVG
    isolatedSVG.setAttribute('width', svgW.toString());
    isolatedSVG.setAttribute('height', svgH.toString());

    // Set the viewBox attribute to match the width and height
    isolatedSVG.setAttribute('viewBox', `0 0 ${svgW} ${svgH}`);

    // Convert the isolated SVG to a data URL
    const svgData = new XMLSerializer().serializeToString(isolatedSVG);
    const dataURL = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgData);

    // Create an anchor element for downloading
    const a = document.createElement('a');
    a.href = dataURL;
    a.download = 'Seating Plan.svg'; // Set the filename for the download

    // Programmatically trigger the download
    a.click();
  };

  useEffect(() => {
    (window as any).download = downloadSeatingPlan;
  }, [width, height]);

  useEffect(() => {
    if (viewMode != ViewMode.Offset && tool == Tool.Drag) {
      setTool(Tool.Pan);
    }
  }, [viewMode]);

  useEffect(() => {
    let resizeTimeout;

    const handleResize = () => {
      clearTimeout(resizeTimeout); // Clear any previous timeout

      // Set a new timeout
      resizeTimeout = setTimeout(() => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        setH(windowHeight);
      }, 1000); // 1000 milliseconds (1 second)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const recenter = () => {
    if (!Viewer || !Viewer.current) return;

    Viewer.current.fitToViewer(ALIGN_CENTER, ALIGN_TOP);

    window.setTimeout(() => {
      if (Viewer && Viewer.current) {
        if (viewMode != ViewMode.Attendee) {
          Viewer.current.zoomOnViewerCenter(0.8, -400);
        } else if (width < 500) {
          Viewer.current.zoomOnViewerCenter(0.84, -150);
        } else if (width < 1024) {
          Viewer.current.zoomOnViewerCenter(0.85, -150);
        } else if (width < 1500) {
          Viewer.current.zoomOnViewerCenter(0.85, -400);
        } else {
          Viewer.current.zoomOnViewerCenter(0.85, -450);
        }
      }
    }, 50);
  };

  const zoomIn = () => {
    Viewer.current.zoomOnViewerCenter(1.6);
  };

  const zoomOut = () => {
    Viewer.current.zoomOnViewerCenter(0.625);
  };

  // Event handler for keydown
  const handleKeyDown = (event) => {
    if (event.key == '+' || event.key == '=') {
      zoomIn();
    }

    if (event.key == '-') {
      zoomOut();
    }

    if (!DeviceHelper.mobileAndTabletCheck()) {
      if (event.key == 'F1') {
        event.preventDefault();
        setTool(Tool.Pan);
      }

      if (event.key == 'F2') {
        event.preventDefault();
        setTool(Tool.Select);
      }

      if (viewMode == ViewMode.Offset && event.key == 'F3') {
        event.preventDefault();
        setTool(Tool.Drag);
      }
    }
    // if (viewMode != ViewMode.Offset && event.key == 'Tab') {
    //   event.preventDefault();
    //   if (tool == Tool.Pan) {
    //     setTool(Tool.Drag);
    //   } else if (tool == Tool.Drag) {
    //     setTool(Tool.Select);
    //   } else if (tool == Tool.Select) {
    //     setTool(Tool.Pan);
    //   }
    // }

    if (event.shiftKey && !shiftKeyPressed) {
      setShiftKeyPressed(true);
    }
    if (event.ctrlKey && !controlKeyPressed) {
      setControlKeyPressed(true);
    }
  };

  // Event handler for keyup
  const handleKeyUp = (event) => {
    if (!event.shiftKey && shiftKeyPressed) {
      setShiftKeyPressed(false);
    }
    if (!event.ctrlKey && controlKeyPressed) {
      setControlKeyPressed(false);
    }
  };

  useEffect(() => {
    // Add keydown event listener when component mounts
    document.addEventListener('keydown', handleKeyDown);
    // Add keyup event listener when component mounts
    document.addEventListener('keyup', handleKeyUp);

    // Remove event listeners when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [viewMode, tool, shiftKeyPressed, controlKeyPressed]);

  const [seatingPlanLabels, setSeatingPlanLabels] = useState<any[]>();

  useEffect(() => {
    recenter();

    (window as any).recenter = recenter;
  }, [props.seatingPlan.Columns, props.seatingPlan.Rows, props.containerWidth]);

  useEffect(() => {
    SeatingPlanHelper.assignGroups(seatingPlan);
    setSeatingPlanLabels(SeatingPlanHelper.getLabels(seatingPlan));
  }, [seatingPlan.Objects]);

  const bannerRef = useRef(null);
  const [justifyContent, setJustifyContent] = useState('center');

  const updateBannerAlignment = () => {
    if (!bannerRef.current) return;

    const banner = bannerRef.current;

    // Check if the content overflows
    if (banner.scrollWidth > banner.clientWidth) {
      setJustifyContent('flex-start'); // Align to left if content overflows
    } else {
      setJustifyContent('center'); // Center items if they fit
    }
  };

  useEffect(() => {
    // Add resize event listener
    window.addEventListener('resize', updateBannerAlignment);

    // Initial alignment check on mount
    updateBannerAlignment();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateBannerAlignment);
    };
  }, []);

  if (viewMode !== ViewMode.Attendee && (props.containerWidth === 0 || !props.containerWidth)) {
    return null;
  }

  if (!seatingPlan) return null;

  const selectByJSON = (seatSelectionCriteria: any) => {
    // Filter the seats based on the JSON criteria
    const seats = [];
    seatingPlan.Seats.forEach((seat) => {
      seat.Selected = false;

      seatSelectionCriteria.forEach((criteria) => {
        if (seat.SeatCategory.Name.toLowerCase().includes(criteria.section.toLowerCase())) {
          if (seat.Group === criteria.group) {
            const seatNumber = parseInt(seat.Name);
            if (seatNumber >= criteria.from && seatNumber <= criteria.to) {
              seats.push(seat);
              seat.Selected = false;
            }
          }
        }
      });
    });

    props.onSeatsSelected && props.onSeatsSelected(seats);
  };

  const invertSelection = () => {
    const newSelectedSeats: ISeatingPlanSeat[] = [];
    const oldSelectedSeats: ISeatingPlanSeat[] = [];

    seatingPlan.Seats.forEach((seat) => {
      if (seat.Selected) {
        oldSelectedSeats.push(seat);
      } else {
        newSelectedSeats.push(seat);
      }
    });

    props.onSeatsSelected && props.onSeatsSelected(oldSelectedSeats);
    props.onSeatsSelected && props.onSeatsSelected(newSelectedSeats);
  };

  (window as any).selectByJSON = selectByJSON;
  (window as any).invertSelection = invertSelection;

  const onPlaceholderClickStart = (c: number, r: number) => {
    clicking = `PLACEHOLDER_${c},${r}`;
  };

  const onPlaceholderClickEnd = (c: number, r: number) => {
    if (clicking === `PLACEHOLDER_${c},${r}`) {
      props.onPlaceholderClick(c, r);
    }
    clicking = null;
  };

  const onObjectClickStart = (object: ISeatingPlanObject) => {
    clicking = object;
  };

  const onObjectClickEnd = (object: ISeatingPlanObject) => {
    if (clicking === object) {
      props.handleObjectClick && props.handleObjectClick(object);
    }
    clicking = null;
  };

  const onSeatClickStart = (seat: ISeatingPlanSeat) => {
    if (seat.Disabled) return;

    clicking = seat;
  };

  const onSeatClickEnd = (seat: ISeatingPlanSeat) => {
    if (seat.Disabled || draggedSeat) return;

    if (clicking === seat) {
      props.onSeatClick && props.onSeatClick(seat, { control: controlKeyPressed, shift: shiftKeyPressed });
    }
    clicking = null;
  };

  const checkInBounds = (columnIndex, rowIndex) => {
    return true;

    // return (
    //   viewMode == ViewMode.Attendee || (columnIndex - 1 >= visibleArea.startX && rowIndex - 1 >= visibleArea.startY && columnIndex <= columns - visibleArea.endX && rowIndex <= rows - visibleArea.endY)
    // );
  };

  const onPan = (e) => {
    setZoomLevel(e.a);

    if (panningTimeout) clearTimeout(panningTimeout);

    if (e.startX != e.endX || e.startY != e.endY) {
      clicking = null;
    } else if (e.lastAction == 'zoom') {
      clicking = null;
    }

    panningTimeout = window.setTimeout(() => {
      const x = e.e;
      const y = e.f;
      const w = elementWidth * e.a;

      const ySteps = y / w;
      const yStepsEnd = (y + rows * w - e.viewerHeight) / w;

      const startY = ySteps < 0 ? 0 - Math.round(ySteps) : 0;
      const endY = yStepsEnd > 0 ? Math.round(yStepsEnd) : 0;

      const xSteps = x / w;
      const xStepsEnd = (x + columns * w - e.viewerWidth) / w;

      const startX = xSteps < 0 ? 0 - Math.round(xSteps) : 0;
      const endX = xStepsEnd > 0 ? Math.round(xStepsEnd) : 0;

      if (
        visibleArea.startY !== startY ||
        visibleArea.startX !== startX ||
        visibleArea.endX !== endX ||
        visibleArea.endY !== endY
      ) {
        setVisibleArea({ startY: startY, endY: endY, startX: startX, endX: endX });
      }
    }, 300);
  };

  var rows = seatingPlan.Rows;
  var midPoint = Math.round(rows / 2);
  var columns = seatingPlan.Columns;
  var quaterPoint = Math.round(columns / 4);
  var quaterPointUpper = Math.round((columns / 4) * 3);

  let seats = seatingPlan.Seats;
  let objects = seatingPlan.Objects;
  let placeholders = [];

  if (viewMode != ViewMode.Attendee) {
    seats = [];
    objects = [];

    for (var rowIndex = 1; rowIndex <= seatingPlan.Rows; rowIndex++) {
      for (var columnIndex = 1; columnIndex <= seatingPlan.Columns; columnIndex++) {
        if (checkInBounds(columnIndex, rowIndex)) {
          const object =
            seatingPlan.Objects &&
            linq
              .from(seatingPlan.Objects)
              .where((o: ISeatingPlanObject) => o.column + 1 == columnIndex && o.row + 1 == rowIndex)
              .firstOrDefault();

          if (object) {
            objects.push(object);
          } else {
            const existingSeat = linq
              .from(seatingPlan.Seats)
              .where((s) => s.Row == rowIndex && s.Column == columnIndex)
              .firstOrDefault();

            if (addPlaceholders) {
              if (existingSeat) {
                existingSeat.Selected = true;
                seats.push(existingSeat);
              } else {
                placeholders.push({ c: columnIndex, r: rowIndex });
              }
            } else if (existingSeat) {
              seats.push(existingSeat);
            }
          }
        }
      }
    }
  }

  const getOutline = () => {
    const objects = seatingPlan.Seats.map((seat) => {
      const xOffset = seat.OffsetX || 0.0;
      const yOffset = seat.OffsetY || 0.0;

      let x = NumberHelper.roundDecimal((seat.Column - 1) * elementWidth + elementWidth / 2);
      let y = NumberHelper.roundDecimal((seat.Row - 1) * elementWidth + elementWidth / 2);

      let style = null;

      if (xOffset != 0.0 || yOffset != 0.0) {
        x = x + NumberHelper.roundDecimal(xOffset * elementWidth);
        y = y + NumberHelper.roundDecimal(yOffset * elementWidth);
      }

      return {
        x: x - elementWidth / 2,
        y: y - elementWidth / 2,
        width: elementWidth,
        height: elementWidth,
      };
    });

    let combinedPath = '';

    // Iterate through each object
    objects.forEach((obj) => {
      const x1 = obj.x;
      const y1 = obj.y;
      const x2 = obj.x + obj.width;
      const y2 = obj.y + obj.height;

      // Extract the outline of the object
      // You may need to implement this part based on your specific objects
      const objectPath = `M ${x1} ${y1} L ${x2} ${y1} L ${x2} ${y2} L ${x1} ${y2} Z`;

      // Add the object's outline to the combined path
      combinedPath += objectPath;
    });

    // Close the combined path to create a continuous loop
    combinedPath += ' Z';

    return <path className="outline" d={combinedPath}></path>;
  };

  const seatScale =
    (viewMode == ViewMode.Offset || viewMode == ViewMode.Attendee || viewMode == ViewMode.Background) &&
    seatingPlan &&
    seatingPlan.SeatScale &&
    seatingPlan.SeatScale > 0
      ? seatingPlan.SeatScale
      : 1.0;

  return (
    <>
      <div
        style={{ zIndex: ZIndexes.PLAN_TOOLBAR }}
        className={`plan-toolbar${viewMode === ViewMode.Attendee ? ' responsive' : ''}`}
      >
        {props.toolbarButtons}
        {props.fullScreenClick && (
          <button onClick={props.fullScreenClick}>
            <label>Full screen</label>
            <SVGFullScreen />
          </button>
        )}

        <button onClick={zoomIn}>
          <label>Zoom in</label>
          <SVGZoomIn />
        </button>
        <button onClick={zoomOut}>
          <label>Zoom out</label>
          <SVGZoomOut />
        </button>
        <button
          className={`${tool == Tool.Pan ? 'active' : ''}`}
          onClick={() => {
            if (tool == Tool.Pan) {
              recenter();
            }
            setTool(Tool.Pan);
          }}
        >
          {/* {!DeviceHelper.mobileAndTabletCheck() && <span className="plan-toolbar-keyboard-shortcut">F1</span>} */}
          <label>Pan</label>
          <SVGPan />
        </button>
        {canSelect && (
          <button
            className={`${tool == Tool.Select ? 'active' : ''}`}
            onClick={() => {
              if (tool != Tool.Select) {
                setTool(Tool.Select);

                if (DeviceHelper.iOS() || DeviceHelper.isIpadOS()) {
                  window.setTimeout(() => {
                    const enabledSeats = seats.filter((s) => !s.Disabled);

                    if (enabledSeats.length > 0) {
                      props.onSeatClick(enabledSeats[0]);
                    }
                  }, 200);
                }
              }
            }}
          >
            {/* {!DeviceHelper.mobileAndTabletCheck() && <span className="plan-toolbar-keyboard-shortcut">F2</span>} */}
            <label>Select</label>
            <SVGDragSelect />
          </button>
        )}
        {viewMode == ViewMode.Offset && (
          <>
            <button
              className={`${tool == Tool.Drag ? 'active' : ''}`}
              onClick={() => {
                setTool(Tool.Drag);
              }}
            >
              {/* {!DeviceHelper.mobileAndTabletCheck() && <span className="plan-toolbar-keyboard-shortcut">F3</span>} */}
              <label>Drag</label>
              <SVGDrag />
            </button>
          </>
        )}
        {/* {mode === EditMode.Attendee && props.onSeatsSelected && (
          <button
            className={`${!disableOffset ? 'active' : ''}`}
            onClick={() => {
              if (!disableOffset) {
                setDisableOffset(true);
              } else {
                setDisableOffset(false);
              }
            }}
          >
            <label>Offset {disableOffset ? 'off' : 'on'}</label>
            <img alt="Toggle Seat Offsets" src={SVGHelper.get('Offset_Light')} />
          </button>
        )} */}
        {props.onPricesClick && (
          <button
            onClick={() => {
              props.onPricesClick();
            }}
          >
            <label>Prices</label>
            <SVGPrice />
          </button>
        )}
        {props.onInfoClick && (
          <button
            onClick={() => {
              props.onInfoClick();
            }}
          >
            <label>Event info</label>
            <SVGInfo />
          </button>
        )}

        {props.moveSeatsLeft && viewMode != ViewMode.Attendee && (
          <div className="group">
            <label>Move items</label>
            <div className="control">
              <button onClick={props.moveSeatsLeft}></button>
              <button onClick={props.moveSeatsUp}></button>
              <button onClick={props.moveSeatsDown}></button>
              <button onClick={props.moveSeatsRight}></button>
            </div>
          </div>
        )}
      </div>

      {canSelect && tool == Tool.Select && (
        <Selecto
          container={document.querySelector('.seating-plan-seats')}
          dragContainer={window}
          selectableTargets={['.seat, .seatingplan-object, .placeholder']}
          selectByClick={false}
          selectFromInside={true}
          continueSelect={false}
          keyContainer={window}
          hitRate={100}
          onSelectEnd={(e) => {
            if (e.rect.height < 5 || e.rect.width < 5) return;

            e.selected.forEach((el) => {
              el.classList.remove('selecting');
            });

            const selectedElements = e.selected;

            const selectedSeats = selectedElements
              .filter((el) => el.classList.contains('seat'))
              .map((el: any) => {
                return seats.filter(
                  (s) => s.Row == parseInt(el.dataset.row) && s.Column == parseInt(el.dataset.column),
                )[0];
              })
              .filter((s) => !s.Disabled);
            props.onSeatsSelected && selectedSeats.length > 0 && props.onSeatsSelected(selectedSeats);

            const selectedPlaceholders = selectedElements
              .filter((el) => el.classList.contains('placeholder'))
              .map((el: any) => {
                return { c: parseInt(el.dataset.column), r: parseInt(el.dataset.row) };
              });
            props.onPlaceholdersSelected &&
              selectedPlaceholders.length > 0 &&
              props.onPlaceholdersSelected(selectedPlaceholders);

            const selectedObjects = selectedElements
              .filter((el) => el.classList.contains('seatingplan-object'))
              .map((el: any) => {
                return seatingPlan.Objects.filter(
                  (s) => s.row == parseInt(el.dataset.row) && s.column == parseInt(el.dataset.column),
                )[0];
              });
            props.onObjectsSelected && selectedObjects.length > 0 && props.onObjectsSelected(selectedObjects);

            props.onSelection && props.onSelection(selectedSeats, selectedPlaceholders, selectedObjects);

            if (selectedSeats.length === 0 && selectedObjects.length === 0 && props.onNothingSelected)
              props.onNothingSelected();
          }}
        />
      )}

      {props.prices && props.event && (
        <div className="key-banner" style={{ justifyContent }} ref={bannerRef}>
          {/* <div className="key-label">
            <div className="key-item-text">Prices: </div>
          </div> */}
          {props.prices &&
            linq
              .from(props.prices)
              .where((p) => {
                return (
                  p &&
                  p.length > 0 &&
                  linq
                    .from(seats)
                    .any((s) => !s.Disabled && p[0].SeatCategory && s.SeatCategoryId == p[0].SeatCategory.Id)
                );
              })
              .orderBy((p) => {
                return p[0].SeatCategory.Index;
              })
              .thenBy((p) => p.PriceAsInt)
              .toArray()
              .map((priceRange: ITicketCategory[]) => {
                const priceGroup = linq.from(priceRange).orderBy((tc) => tc.PriceAsInt);

                const minPrice = FeeHelper.getAmountWithFeesFormatted(
                  props.isAdmin,
                  props.event,
                  priceGroup.first().PriceAsInt,
                ).replace('.00', '');

                const maxPrice = FeeHelper.getAmountWithFeesFormatted(
                  props.isAdmin,
                  props.event,
                  priceGroup.last().PriceAsInt,
                ).replace('.00', '');

                const seatCategory = priceGroup.first().SeatCategory;

                return (
                  <div
                    className={`key-item${visibleSeatCategories.includes(seatCategory.Id) ? ' key-item__selected' : ''}`}
                    key={priceGroup.first().Id}
                    onClick={() => {
                      const seatCategoryId = seatCategory.Id;

                      // If visibleSeatCategories contains the seat category, remove it else add it
                      if (visibleSeatCategories.includes(seatCategoryId)) {
                        setVisibleSeatCategories(visibleSeatCategories.filter((id) => id !== seatCategoryId));
                      } else {
                        setVisibleSeatCategories([...visibleSeatCategories, seatCategoryId]);
                      }
                    }}
                  >
                    <div className="key-item-label">{seatCategory.Name}</div>
                    <div className="key-item-color" style={{ backgroundColor: seatCategory.Colour }}>
                      {seatCategory.Icon == 'wheelchair' && (
                        <img className="key-item-wheelchair" src={SVGHelper.get('Wheelchair_Light')} />
                      )}
                      {seatCategory.Icon == 'info' && <img src={SVGHelper.get('Info_Solo_Light')} />}
                    </div>
                    <div className="key-item-text">{minPrice == maxPrice ? minPrice : `${minPrice} - ${maxPrice}`}</div>
                  </div>
                );
              })}

          {FeeHelper.getServiceFeeMessage(props.isAdmin, props.event)}
        </div>
      )}

      <SVGPanZoom
        detectWheel={false}
        detectAutoPan={false}
        ref={Viewer}
        scaleFactor={1.3}
        width={parseFloat(width.toFixed(2))}
        height={parseFloat(height.toFixed(2))}
        tool={tool != Tool.Pan ? TOOL_NONE : TOOL_PAN}
        onPan={onPan}
        onZoom={onPan}
        onTouchMove={() => {
          clicking = null;
        }}
      >
        <svg
          width={elementWidth * columns}
          height={elementWidth * rows}
          viewBox={`0 0 ${elementWidth * columns} ${elementWidth * rows}`}
        >
          {seatingPlan.BackgroundUrl &&
            (viewMode == ViewMode.Offset || viewMode == ViewMode.Background || viewMode == ViewMode.Attendee) && (
              <image
                className="seating-plan-background"
                x={seatingPlan.BackgroundX.toString()}
                y={seatingPlan.BackgroundY.toString()}
                width={seatingPlan.BackgroundWidth.toString()}
                preserveAspectRatio="xMinYMin meet"
                xlinkHref={seatingPlan.BackgroundUrl}
              />
            )}

          {linq
            .from(objects)
            .where((l) => (viewMode === ViewMode.Attendee && l.designMode !== 'label') || viewMode != ViewMode.Attendee)
            .toArray()
            .map((object) => (
              <SeatingPlanObject
                handleClick={null}
                key={'Object_' + object.column + '_' + object.row}
                seatingPlan={seatingPlan}
                object={object}
                clickStart={onObjectClickStart}
                clickEnd={onObjectClickEnd}
                height={elementWidth}
                width={elementWidth}
              />
            ))}

          {seatingPlanLabels}
          <g className="seating-plan-seats">
            {placeholders.map((placeholder) => (
              <SeatingPlanPlaceholder
                mobileAndTablet={mobileAndTablet}
                key={`Seat_${placeholder.c}_${placeholder.r}`}
                clickStart={onPlaceholderClickStart}
                clickEnd={onPlaceholderClickEnd}
                w={elementWidth}
                c={placeholder.c}
                r={placeholder.r}
              />
            ))}

            {seats
              .filter((s) => !s.Selected)
              .map((seat) => (
                <SeatingPlanSeat
                  seatScale={seatScale}
                  onDrag={
                    tool == Tool.Drag
                      ? (e) => {
                          if (props.onSeatDrag) {
                            setDraggedSeat(e.seat);
                            props.onSeatDrag({
                              ...e,
                              zoomLevel: zoomLevel,
                              planWidth: seatingPlan.Columns * elementWidth,
                              planHeight: seatingPlan.Rows * elementWidth,
                            });
                          }
                        }
                      : null
                  }
                  onDragEnd={() => {
                    setDraggedSeat(null);
                  }}
                  hideIcon={viewMode != ViewMode.Attendee || props.disabled || seat.Disabled}
                  mobileAndTablet={mobileAndTablet}
                  disableOffset={disableOffset}
                  key={`Seat_${seat.Column}_${seat.Row}`}
                  disabled={
                    props.disabled ||
                    seat.Disabled ||
                    (visibleSeatCategories.length > 0 && !visibleSeatCategories.includes(seat.SeatCategory.Id))
                  }
                  leftHalf={seat.Column <= quaterPoint}
                  rightHalf={seat.Column >= quaterPointUpper}
                  bottomHalf={seat.Row >= midPoint}
                  showCategoryColours={showCategoryColours}
                  clickStart={onSeatClickStart}
                  clickEnd={onSeatClickEnd}
                  seat={seat}
                  height={elementWidth}
                  width={elementWidth}
                  hideOrders={!props.showOrders}
                  isMiddleSeat={viewMode == ViewMode.Attendee && seat.isMiddleSeat}
                />
              ))}

            {seats
              .filter((s) => s.Selected)
              .map((seat) => (
                <SeatingPlanSeat
                  seatScale={seatScale}
                  onDrag={
                    tool == Tool.Drag
                      ? (e) => {
                          if (props.onSeatDrag) {
                            setDraggedSeat(e.seat);
                            props.onSeatDrag({
                              ...e,
                              zoomLevel: zoomLevel,
                              planWidth: seatingPlan.Columns * elementWidth,
                              planHeight: seatingPlan.Rows * elementWidth,
                            });
                          }
                        }
                      : null
                  }
                  onDragEnd={() => {
                    setDraggedSeat(null);
                  }}
                  isMiddleSeat={viewMode == ViewMode.Attendee && seat.isMiddleSeat}
                  hideIcon={viewMode == ViewMode.Design || viewMode == ViewMode.Label}
                  mobileAndTablet={mobileAndTablet}
                  disableOffset={disableOffset}
                  key={`Seat_${seat.Column}_${seat.Row}`}
                  disabled={props.disabled || seat.Disabled}
                  leftHalf={seat.Column <= quaterPoint}
                  rightHalf={seat.Column >= quaterPointUpper}
                  bottomHalf={seat.Row >= midPoint}
                  showCategoryColours={showCategoryColours}
                  clickStart={onSeatClickStart}
                  clickEnd={onSeatClickEnd}
                  seat={seat}
                  height={elementWidth}
                  width={elementWidth}
                  hideOrders={!props.showOrders}
                />
              ))}
          </g>
        </svg>
      </SVGPanZoom>
    </>
  );
};

export default SeatingPlanSvg;
