import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import eventMenuState from '../../atoms/eventMenuState';
import loaderState from '../../atoms/loaderState';
import tourMenuState from '../../atoms/tourMenuState';
import Modal from '../../components/Modal';
import SpacerTable from '../../components/SpacerTable';
import EnvironmentVariables from '../../EnvironmentVariables';
import CacheHelper from '../../helpers/CacheHelper';
import EventHelper from '../../helpers/EventHelper';
import GAHelper from '../../helpers/GAHelper';
import ThemeHelper from '../../helpers/ThemeHelper';
import UserHelper from '../../helpers/UserHelper';
import { IEvent } from '../../interfaces/IEvent';
import { IEventDate } from '../../interfaces/IEventDate';
import SVGCalendar from '../../svg/SVGCalendar';
import SVGInfo from '../../svg/SVGInfo';
import SVGOptions from '../../svg/SVGOptions';
import './DatesModal.scss';
import EventDates from './EventDates';
import TourModalDates from './TourModalDates';
import PixelHelper from '../../helpers/PixelHelper';

export interface IProps {
  event?: IEvent;
  eventTag?: string;
  showEventOnly?: boolean;
  currentEventDate?: IEventDate;
  isAdmin?: boolean;
  onClose?: () => void;
}

const DatesModal = ((props) => {
  const [event, setEvent] = useState<IEvent>(props.event ? props.event : null);
  const [showPastDates, setShowPastDates] = useState(false);
  const [loaders, setLoaders] = useRecoilState(loaderState);
  const tour = event && event.Tour;
  const [, setTourMenu] = useRecoilState(tourMenuState);
  const [, setEventMenu] = useRecoilState(eventMenuState);

  const isAdmin = event && UserHelper.isCurrentUserAdmin(event.Organisation);

  const initialise = (e: IEvent) => {
    GAHelper.modal(`${e.EventTag} Dates`);

    let pixel = false;
    if (e.FacebookPixelId) {
      pixel = true;
      PixelHelper.init(e.FacebookPixelId, null, {
        autoConfig: true,
        debug: EnvironmentVariables.isDev(),
      });
    }

    if (e.Organisation && e.Organisation.FacebookPixelId) {
      pixel = true;
      PixelHelper.init(e.Organisation.FacebookPixelId, null, {
        autoConfig: true,
        debug: EnvironmentVariables.isDev(),
      });
    }

    if (e.Tour && e.Tour.FacebookPixelId) {
      pixel = true;
      PixelHelper.init(e.Tour.FacebookPixelId, null, {
        autoConfig: true,
        debug: EnvironmentVariables.isDev(),
      });
    }

    if (pixel) {
      const data = {
        eventName: e.Name,
        venue: e.Venue.Name,
        eventTag: e.EventTag,
        organisation: e.Organisation.Name,
        url: window.location.href,
      };
      if (e.FacebookPixelId) PixelHelper.trackSingleCustom(e.FacebookPixelId, 'DatePickerView', data);
      if (e.Organisation && e.Organisation.FacebookPixelId)
        PixelHelper.trackSingleCustom(e.Organisation.FacebookPixelId, 'DatePickerView', data);
      if (e.Tour && e.Tour.FacebookPixelId)
        PixelHelper.trackSingleCustom(e.Tour.FacebookPixelId, 'DatePickerView', data);
    }
  };

  useEffect(() => {
    if (event) {
      initialise(event);
      return;
    }

    if (props.eventTag)
      CacheHelper.eventWithMessage(setLoaders, loaders, props.eventTag).then((e) => {
        setEvent(e);
        initialise(e);
      });
  }, []);

  const pastDates = event && EventHelper.getPastDates(event, isAdmin);

  const nameSameAsVenue =
    event && event.Organisation.Name.toLowerCase().trim() == event.Venue.Name?.toLowerCase().trim();

  return (
    event && (
      <Modal theme={ThemeHelper.getEventTheme(event)} onCloseClick={props.onClose}>
        <div className="content dates-modal">
          <div className={`ticket-rip dark`} />

          <div className="body">
            <div className="dates-header">
              <SpacerTable>
                {tour ? <h1>{tour.Name}</h1> : <h1>{event.Name}</h1>}

                <div style={{ fontWeight: 500 }}>
                  {event.Organisation.Name}
                  {event.Organisation && event.Organisation.IsMember && (
                    <>
                      {' '}
                      <strong>(Member)</strong>
                    </>
                  )}
                </div>
                {!tour && (
                  <div>
                    {!nameSameAsVenue && event.Venue.Name?.trim()}
                    {event.Venue.City && `${nameSameAsVenue ? '' : ', '}${event.Venue.City?.trim()}`}
                    {event.Venue.Postcode && `, ${event.Venue.Postcode?.trim()}`}
                  </div>
                )}
              </SpacerTable>
            </div>
            <div className="toolbar">
              <div className="buttons left">
                {tour && !props.showEventOnly ? (
                  <Link to={`/Tour/${tour.TourTag}`} onClick={props.onClose}>
                    <SVGInfo />
                    Tour info
                  </Link>
                ) : (
                  <Link to={`/${event.EventTag}`} onClick={props.onClose}>
                    <SVGInfo />
                    Info
                  </Link>
                )}

                {!tour && pastDates.length > 0 && (
                  <a href="#" className={showPastDates ? 'disabled' : ''} onClick={() => setShowPastDates(true)}>
                    <SVGCalendar />
                    Old dates
                  </a>
                )}

                {isAdmin &&
                  (!tour ? (
                    <button
                      onClick={() => {
                        setEventMenu({ eventTag: event.EventTag });
                      }}
                    >
                      <SVGOptions />
                      Options
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setTourMenu(tour);
                      }}
                    >
                      <SVGOptions />
                      Options
                    </button>
                  ))}
              </div>
            </div>

            <div className="spacer" />
            {tour && !props.showEventOnly ? (
              <TourModalDates
                onEventDateClicked={props.onClose}
                isAdmin={isAdmin}
                showPastDates={showPastDates}
                tour={tour}
                onVenueLinkClick={props.onClose}
              />
            ) : (
              <>
                <EventDates
                  onEventDateClicked={props.onClose}
                  events={[event]}
                  showPastDates={showPastDates}
                  isAdmin={isAdmin}
                />
              </>
            )}
          </div>

          <div className="ticket-rip bottom" />
        </div>
      </Modal>
    )
  );
}) as FunctionComponent<IProps>;

export { DatesModal };
